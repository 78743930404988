
export class LocationHashHandler {
    store = null;

    async init({ store }) {
        this.store = store;
        await this._initHandlers();
    }

    async _initHandlers() {
        window.addEventListener('hashchange', () => this._hashChangeHandler());
        document.addEventListener('DOMContentLoaded', () => this._hashChangeHandler(), false);
    }

    _hashChangeHandler() {
        const locationHash = window.location.hash.replace('#', '');

        switch (locationHash) {
            case 'signup':
                this.store.dispatch('modalManagementSystem/addModalWindow', {
                    content: 'SignUpForm',
                });  
                break;
            case 'login':
                this.store.dispatch('modalManagementSystem/addModalWindow', {
                    content: 'SignInForm',
                });
                break;

            default:
        }
    }
}