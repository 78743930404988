export class Webinar {
    public store;

    async init({ store }) {
        this.store = store;
        this._websiteHeaderWebinarHandler();
    }

    _websiteHeaderWebinarHandler() {
        var wID = 117;
        var wSettings = {
            isActive: false,
            isUserHideWebinarKey: 'userHideWebinar' + wID,
            regHref: 'https://attendee.gotowebinar.com/register/2837683796036904716',
            date: `Thu, May 20, 2021`,
            time: '2PM EDT',
            //???
            dateCheck: new Date(2018, 11, 27, 9, 0, 0, 0).getTime()
            //
        }

        var wControls = {
            mainBox: document.querySelector('.webinar'),
            joinWorkshop: document.querySelector('.webinar__button--join-workshop'),
            closeWebinarAnnouncement: document.querySelector('.webinar__button--close-announcement'),
            titleBox: document.querySelector('#header-webinar_main_box-js .header-webinar_row_description-js'),
            regHrefBtn: document.querySelector('#header-webinar_main_box-js .header-webinar_row_link_to_registration-js'),
            mobRegHrefBtn: document.querySelector('#footer-mob-webinar_main_box-js .header-mob-webinar_row_link_to_page'),
            wLayoutBodyFixCssClassName: 'body-header-webinar_offset_fix_active',
            showWebinarsButton: document.querySelector('.webinar__button-text--call-to-watch')
        }

        function hasEnabledWebinar() {
            return wSettings.isActive;
        }

        function isWebinarMimized() {
            const isUserMinimizedWebinar = document.cookie.match(new RegExp(
                "(?:^|; )" + wSettings.isUserHideWebinarKey.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));

            const isUserHideWebinarResult = isUserMinimizedWebinar ? decodeURIComponent(isUserMinimizedWebinar[1]) : undefined;

            return isUserHideWebinarResult === 'yes';

        }

        function wActive() {
            var isUserHideWebinar: any = document.cookie.match(new RegExp(
                "(?:^|; )" + wSettings.isUserHideWebinarKey.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));

            isUserHideWebinar = isUserHideWebinar ? decodeURIComponent(isUserHideWebinar[1]) : undefined;

            if (isUserHideWebinar != 'yes' && wSettings.isActive) {
                return true
            }

            return false
        }
        if (hasEnabledWebinar()) {
            wControls.mainBox.classList.add('webinar--active');

            if (isWebinarMimized()) {
                wControls.mainBox.classList.add('webinar--minimized');
            }
        }


        wControls.closeWebinarAnnouncement.addEventListener('click', (event) => {
            document.cookie = wSettings.isUserHideWebinarKey + '=yes;';
            wControls.mainBox.classList.add('webinar--minimized');
        });

        wControls.joinWorkshop.addEventListener('click', () => {
            document.cookie = wSettings.isUserHideWebinarKey + '=no;';
            wControls.mainBox.classList.remove('webinar--minimized');
        })

        wControls.showWebinarsButton.addEventListener('click', () => {
            this.store.dispatch('modalManagementSystem/addModalWindow', {
                content: 'Webinars'
            })
        })  

    }
} 