import { defineComponent, ref, reactive } from 'vue';
import { RecaptchaVersion2 } from '/blocks/common/components/recaptcha-version-2';
import axios, { AxiosRequestConfig } from 'axios';

export const RegisterForFreeClassForm = defineComponent({
    name: 'RegisterForFreeClassForm',
    components: {
        RecaptchaVersion2
    },
    emits: ['close'],
    setup(props, { emit }) {
        const state = reactive({
            recaptchaResponse: null,
            error: ''
        });

        let registerForFreeClassFormElement = ref(null);

        const close = () => {
            emit('close');
        };

        const onVerifyRecaptcha = (event) => {
            state.recaptchaResponse = event.response;
        };

        const _validation = async () => {
            if (state.recaptchaResponse === null) {
                state.error = 'Please check the captcha box to proceed register for a class';
                return false;
            }

            return true;
        }

        const submitRegisterForFreeClass = async (event) => {
            event.preventDefault();

            const validationResult = await _validation();
            if (validationResult === false) {
                return;
            }

            const registerForFreeClassFormData = new FormData(registerForFreeClassFormElement.value);
            registerForFreeClassFormData.append('action', 'registerForFreeClass');

            const loginOpt: AxiosRequestConfig = {
                method: 'post',
                url: 'supportHandler.ashx',
                data: registerForFreeClassFormData,
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            axios(loginOpt)
                .then((response) => {
                    if (response.data && response.data.result === true) {
                        emit('close');
                    }
                });
        }

        return () => (
            <div class="register-for-free-class-form">
                <div class="register-for-free-class-form__layout">
                    <div class="register-for-free-class-form__header">
                        <div class="register-for-free-class-form__heading-1">Registration form</div>
                        <svg class="register-for-free-class-form__header-icon" width="71" height="70" viewBox="0 0 71 70" fill="none">
                            <path d="M29.5707 69.9779C20.6299 69.9779 11.6891 69.9779 2.74826 69.9779C0.545771 69.9779 -0.0212076 69.4112 0.000599257 67.1883C0.109634 58.9941 3.5115 52.3253 9.68284 47.0514C14.6548 42.8017 20.4772 40.7749 27.0411 40.8621C29.9414 40.9057 32.8635 40.8403 35.7638 40.8839C39.4274 40.9492 42.8511 41.9735 46.1439 43.4991C46.7327 43.7824 47.147 43.8042 47.714 43.4337C56.524 37.8764 68.0598 42.867 69.7825 53.1099C71.418 62.8079 63.7638 70.065 55.8261 69.9997C47.0816 69.9125 38.3152 69.9779 29.5707 69.9779ZM44.8791 65.5756C44.9009 65.2269 44.5956 65.0744 44.4212 64.8782C42.1969 62.1759 41.0847 59.0594 41.0411 55.5507C41.0193 52.8266 41.848 50.3421 43.2 48.0103C43.5271 47.4436 43.5489 47.0949 42.8511 46.877C41.0193 46.3104 39.2093 45.6348 37.2685 45.4822C32.8417 45.1553 28.4149 45.09 24.0099 45.504C18.7763 46.0053 14.3931 48.3154 10.7514 52.0856C7.52396 55.3982 5.53954 59.3645 4.71088 63.9193C4.40558 65.6192 4.36197 65.6846 6.0411 65.6846C18.6455 65.6846 31.2498 65.6846 43.8324 65.6846C44.1595 65.6846 44.5302 65.7936 44.8791 65.5756ZM45.4897 55.6161C45.4897 61.0644 50.1564 65.7064 55.5863 65.6846C60.9726 65.6628 65.6174 61.0426 65.6392 55.6379C65.661 50.2114 60.9944 45.5476 55.5645 45.5476C50.0255 45.5476 45.4897 50.0806 45.4897 55.6161Z" fill="white" />
                            <path d="M12.2125 18.5897C11.9072 8.5648 20.7826 0.0218339 30.7265 4.06738e-05C40.7795 -0.0217526 49.3277 8.71735 49.3059 18.5679C49.2841 28.5492 40.7358 37.2447 30.6829 37.0922C20.4119 36.9396 12.2343 28.8761 12.2125 18.5897ZM16.6393 18.4371C16.5957 26.2609 22.8324 32.6028 30.6829 32.6899C38.4025 32.7771 44.9446 26.2609 44.9446 18.4807C44.9446 10.9403 38.4897 4.3587 30.901 4.40228C22.9851 4.44587 16.7701 10.4608 16.6393 18.4371Z" fill="white" />
                            <path d="M55.5427 61.8711C54.4306 61.8711 53.5365 61.0647 53.4275 59.9533C53.4057 59.7354 53.4057 59.5174 53.4057 59.2995C53.3838 57.774 53.3838 57.774 51.8574 57.774C50.1564 57.7522 49.2624 56.9458 49.306 55.5293C49.3496 54.3524 50.0692 53.5897 51.2904 53.5025C51.7265 53.4807 52.1627 53.4807 52.5988 53.4807C53.2094 53.5025 53.4493 53.1974 53.4275 52.609C53.4057 52.2167 53.4057 51.8026 53.4275 51.4103C53.4929 50.1463 54.2561 49.3836 55.4991 49.3618C56.7421 49.34 57.6144 50.1463 57.7234 51.4103C57.8761 53.3281 57.8761 53.3281 59.8387 53.4807C61.0162 53.5679 61.8449 54.4614 61.8449 55.6382C61.8449 56.7933 61.0162 57.665 59.8169 57.774C57.8979 57.9265 57.8979 57.9265 57.7234 59.8879C57.5926 61.043 56.6985 61.8929 55.5427 61.8711Z" fill="white" />
                        </svg>
                        <button class="register-for-free-class-form__button register-for-free-class-form__button--close" type="button" onClick={close}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M18 2L2 18" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M2 2L18 18" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                    <div class="register-for-free-class-form__body">
                        <form
                            class="register-for-free-class-form__form"
                            ref={registerForFreeClassFormElement}
                            onSubmit={submitRegisterForFreeClass}
                        >
                            <div class="register-for-free-class-form__form-element">
                                <label class="register-for-free-class-form__form-label">Name</label>
                                <input class="register-for-free-class-form__form-input" type="text" name="name" required />
                            </div>
                            <div class="register-for-free-class-form__form-element">
                                <label class="register-for-free-class-form__form-label">Email</label>
                                <input class="register-for-free-class-form__form-input" type="email" name="email" required />
                            </div>
                            <div class="register-for-free-class-form__form-element register-for-free-class-form__form-element--phone">
                                <label class="register-for-free-class-form__form-label">Phone number</label>
                                <input class="register-for-free-class-form__form-input" type="text" name="phone" />
                            </div>
                            <div class="register-for-free-class-form__form-element register-for-free-class-form__form-element--recaptcha">
                            <RecaptchaVersion2 onVerify={onVerifyRecaptcha}></RecaptchaVersion2>
                            </div>
                            <div class="register-for-free-class-form__form-element register-for-free-class-form__form-element--error">
                                {state.error}
                            </div>

                            <button class="register-for-free-class-form__button register-for-free-class-form__button--send-request">
                                <span class="register-for-free-class-form__button-text">Send request</span>
                            </button>
                        </form>
                    </div>
                </div >
            </div>
        )
    }
});

export type RegisterForFreeClassForm = InstanceType<typeof RegisterForFreeClassForm>