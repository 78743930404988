import { defineComponent, ref, reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';
import ModalItem from './components/modal-item/modal-item.vue';

export const ModalManagementSystem = defineComponent({
    name: 'ModalManagementSystem',
    components: {
        ModalItem
    },
    setup() {
        const store = useStore();
        const root = ref(null);
        const state = reactive({
            scrollY: 0,
            isShowed: false
        });
        const modals = computed(() => store.state.modalManagementSystem.modals);
        const onCloseHandler = (event) => {
            store.dispatch('modalManagementSystem/removeModalWindow', { modal: event.modal })
        };
        watch(() => store.state.modalManagementSystem.modals, (newValueModal, prevValueModals) => {
            const body = document.body;
            if (newValueModal !== undefined && store.state.modalManagementSystem.modals.length > 0 && state.isShowed === false) {
                state.scrollY = window.scrollY;
                body.style.position = 'fixed';
                body.style.width = `100%`;
                body.style.height = `100%`;
                body.style.top = `-${state.scrollY}px`;
                state.isShowed = true;
            } else if (store.state.modalManagementSystem.modals.length === 0){
                const scrollY = body.style.top;
                body.style.position = '';
                body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
                state.isShowed = false;
            }
        }, {
                immediate: true,
                deep: true,
        });

        return () => (
            <div class="modals">
                {
                    modals.value.map((modal) => {
                        return <ModalItem modal={modal} key={modal.id} onClose={onCloseHandler}></ModalItem>
                    })
                }
            </div>
        )
    },
})

export type ModalManagementSystem = InstanceType<typeof ModalManagementSystem>