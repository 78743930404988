import { defineComponent, reactive, computed, ref, toRefs, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';

export const StepUpload = defineComponent({
    name: 'StepUpload',
    emits: ['cancel', 'nextStep', 'uploaded'],
    components: {

    },
    setup(props, { emit }) {
        const store = useStore();
        const refInputFile = ref(null);
        const refForm = ref(null);

        const state = reactive({
            droppedFiles: null,
        });

        const dragDefaultDisable = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        const dragOverEnter = (event) => {
            refForm.value.classList.add('is-dragover');
        };

        const removeIsDragOver = (event) => {
            refForm.value.classList.remove('is-dragover');
        };

        const onDrop = (event) => {
            state.droppedFiles = event.dataTransfer.files;
            triggerFormSubmit();
        };

        const triggerFormSubmit = () => {
            const event = document.createEvent('HTMLEvents');
            event.initEvent('submit', true, false);
            refForm.value.dispatchEvent(event);
        }

        const onChangeForm = (event) => {
            state.droppedFiles = event.target.files;
            triggerFormSubmit();
        }


        const onSubmit = (e) => {
            if (!state.droppedFiles) {
                return;
            }

            let validFileExtensions = ["jpg", "jpeg", "bmp", "gif", "png", "pdf", "webp", "doc", "docx", "xls", "xlsx", "odt", "ods", "odp", "odg"];
            let isValidExtension = false;
            let isValidSize = true;
            let ext = state.droppedFiles[0].name.split('.').pop().toLowerCase();

            for (let i = 0; i < validFileExtensions.length; i++) {
                if (ext === validFileExtensions[i]) {
                    isValidExtension = true;
                }
            }

            if (!isValidExtension) {
                refForm.value.reset();
                alert(ext + 'is unacceptable file format. ' + 'Please use of the following: ' + validFileExtensions);
                return;
            }


            refForm.value.classList.add('is-uploading');
            refForm.value.classList.remove('is-error');


            const formData = new FormData();

            if (state.droppedFiles) {
                Array.prototype.forEach.call(state.droppedFiles, function (file) {
                    formData.append('Files', file);
                    formData.append('timehash', file.name);
                });
            }

            const url = 'handlers/uploadhandler.ashx?action=uploadFile';

            axios
                .post(url, formData)
                .then((response) => {
                    emit('uploaded', { fileUrl: response.data, fileName: state.droppedFiles[0].name });
                });
        }

        onMounted(() => {
            refForm.value.addEventListener('drag', dragDefaultDisable);
            refForm.value.addEventListener('dragstart', dragDefaultDisable);
            refForm.value.addEventListener('dragend', dragDefaultDisable);
            refForm.value.addEventListener('dragover', dragDefaultDisable);
            refForm.value.addEventListener('dragenter', dragDefaultDisable);
            refForm.value.addEventListener('dragleave', dragDefaultDisable);
            refForm.value.addEventListener('drop', dragDefaultDisable);

            refForm.value.addEventListener('dragover', dragOverEnter);
            refForm.value.addEventListener('dragenter', dragOverEnter);

            refForm.value.addEventListener('dragleave', removeIsDragOver);
            refForm.value.addEventListener('dragend', removeIsDragOver);
            refForm.value.addEventListener('drop', removeIsDragOver);

            refForm.value.addEventListener('drop', onDrop);
            refForm.value.addEventListener('submit', onSubmit);
            refForm.value.addEventListener('change', onChangeForm);
        });

        const uploadFile = () => refInputFile.value.click();
        const cancel = () => emit('cancel');
        const back = () => emit('nextStep', { stepName: 'howYouWantConvert' });

        return () => (
            <div class="step-upload">
                <div class="step-upload__header">
                    <button class="step-upload__button step-auth__button--header-cancel" onClick={cancel}>
                        <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                    </button>
                </div>
                    <div class="step-upload__body">
                        <div class="step-upload__uploading-container">
                            <form class="step-upload__form"
                                ref={refForm}
                                method="post"
                                action=""
                                enctype="multipart/form-data">
                                <div>
                                    <img class="upload-pdf-body__image" src="storage/images/home-page/v1/heroSection/1-10.png" />
                                </div>

                                <h3 class="step-upload__heading">Upload your PDF or image file</h3>
                            <button
                                class="step-upload__button step-upload__button--download"
                                onClick={uploadFile}
                                type="button">
                                Upload my form
                            </button>
                            <div class="upload-pdf-body-box__input">
                                <input ref={refInputFile} class=" box__file" type="file" name="files[]" id="file" data-multiple-caption="{count} files selected" multiple />
                            </div>

                            <div class="box__uploading">Uploading&hellip;</div>
                            <div class="box__success">Done!</div>
                            <div class="box__error">Error! <span></span>.</div>
                </form>
                    </div>
                </div>
                <div class="step-upload__footer">
                    <button class="step-upload__button step-upload__button--back" onClick={back}>
                        Back
                    </button>
                    <button class="step-upload__button step-upload__button--cancel" onClick={cancel}>
                        Cancel
                    </button>
                </div>
            </div>
        )
    },
})

export type StepUpload = InstanceType<typeof StepUpload>