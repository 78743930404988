export const mutations = {
    addModalWindow(state, modal) {
        modal.id = state.counter;
        state.modals.push(modal);
    },
    removeModalWindow(state, modal) {
        const foundedModalIndex = state.modals.indexOf(modal);

        if (foundedModalIndex === -1) {
            return;
        }

        state.counter++;
        state.modals.splice(foundedModalIndex, 1);
    },
}
