import axios, { AxiosRequestConfig } from 'axios';
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { addStat, setCookie, getCookie, guid } from '/blocks/common/components/utility';

export const StepHowYouWantConvert = defineComponent({
    name: 'StepHowYouWantConvert',
    emits: ['cancel', 'back', 'nextStep'],
    setup(context, { emit }) {
        function cancelHandler() {
            emit('cancel');
        };

        function toIntroHandler() {
            emit('nextStep', { stepName: 'startIntro' });
        };

        function backHandler() {
            emit('back');
        };

        const store = useStore();
        const helpUploadElement = ref(null);
        const helpChooseElement = ref(null);
        const helpBlankElement = ref(null);
        const root = ref(null);

        function chooseTemplateHandler() {
            emit('nextStep', { stepName: 'chooseTemplate' });
        };

        function uploadHandler() {
            emit('nextStep', { stepName: 'upload' });
        };

        function helpChooseHandler(event){
            helpUploadElement.value.classList.remove('step-how-you-want-convert__conversion-types-help--active');
            helpBlankElement.value.classList.remove('step-how-you-want-convert__conversion-types-help--active');
            helpChooseElement.value.classList.toggle('step-how-you-want-convert__conversion-types-help--active');
        };

        const helpUploadHandler = () => {
            helpChooseElement.value.classList.remove('step-how-you-want-convert__conversion-types-help--active');
            helpBlankElement.value.classList.remove('step-how-you-want-convert__conversion-types-help--active');
            helpUploadElement.value.classList.toggle('step-how-you-want-convert__conversion-types-help--active');
        }

        const helpBlank = () => {
            helpChooseElement.value.classList.remove('step-how-you-want-convert__conversion-types-help--active');
            helpUploadElement.value.classList.remove('step-how-you-want-convert__conversion-types-help--active');
            helpBlankElement.value.classList.toggle('step-how-you-want-convert__conversion-types-help--active');
        }

        const createBlankApp = async () => {
            root.value.classList.add('step-how-you-want-convert--loading');
            setCookie('editorExpertMode', '0');
            setCookie('headerVisible', '1');
            const createAppFormData = new FormData();
            createAppFormData.append('mode', 'form');
            createAppFormData.append('type', 'createNewApp');
            createAppFormData.append('userId', getCookie('userid'));
            createAppFormData.append('guid', getCookie('userid'));
            createAppFormData.append('sessionkey', guid());
            createAppFormData.append('defaultCanvas', 'standart');


            const createAppRequestConfig: AxiosRequestConfig = {
                method: 'post',
                url: 'handlers/appbuilder.ashx',
                data: createAppFormData,
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            try {
                const createAppResult = await axios(createAppRequestConfig);
                const msg = createAppResult.data;

                if (msg && msg.appId > 0) {
                    root.value.classList.remove('step-how-you-want-convert--loading');
                    window.location.href = "wizard.aspx?cmd=loadApp&appId=" + msg.appId + "&guid=" + getCookie("userguid");

                    return;
                } else {
                    throw 'Server does not return new appId';
                }
            } catch (e) {
            }

        };

        const watchCreatingFromTemplateYoutubeHandler = () => {
            store.dispatch('modalManagementSystem/addModalWindow', {
                content: 'Youtube',
                url: 'https://www.youtube.com/embed/zPIsbGUq0tw',
                autoplay: true,
            })
        }

        const watchUploadOwnPdfYoutubeHandler = () => {
            store.dispatch('modalManagementSystem/addModalWindow', {
                content: 'Youtube',
                url: 'https://www.youtube.com/embed/6c80T3HeRC4',
                autoplay: true,
            })
        }

        function watchCreatingBlankApplicationYoutubeHandler(){
            store.dispatch('modalManagementSystem/addModalWindow', {
                content: 'Youtube',
                url: 'https://www.youtube.com/embed/ey6Dv7EN6zs',
                autoplay: true,
            })
        }

        return () => (
            <div ref={root} class="step-how-you-want-convert">
                <div class="step-how-you-want-convert__header">
                    <button class="step-how-you-want-convert__button step-how-you-want-convert__button--go-intro" onClick={toIntroHandler}>
                        <img src="storage/images/home-page/v1/heroSection/asset-40.png"/>
                    </button>
                    <button class="step-how-you-want-convert__button step-how-you-want-convert__button--header-cancel" onClick={cancelHandler}>
                        <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                    </button>
                </div>
                        <div class="step-how-you-want-convert__body">
                            <div class="step-how-you-want-convert__desktop">
                                <p class="step-how-you-want-convert__heading">
                                    How do you want to convert your form?
                                </p>
                                <div class="step-how-you-want-convert__conversion-types-list">
                                    <div class="step-how-you-want-convert__conversion-types-wrapper">
                                        <div class="step-how-you-want-convert__conversion-types-item">
                                            <img
                                                class="step-how-you-want-convert__conversion-types-image"
                                                width="180" height="180"
                                                src="storage/images/blocks/common/convert-my-form/form-convert-flow/step-how-you-want-convert/choose.png"
                                            />
                                            <p class="step-how-you-want-convert__conversion-types-heading">
                                                Choose
                                                <button
                                                    class="step-how-you-want-convert__button step-how-you-want-convert__button--help"
                                                    onClick={helpChooseHandler}
                                                    type="button"
                                                >
                                                    <span class="step-how-you-want-convert__button-caption">?</span>
                                                </button>
                                            </p>
                                            <p class="step-how-you-want-convert__conversion-types-description">
                                                100+ professional<br />
                                                modifiable templates
                                            </p>
                                            <button
                                                class="step-how-you-want-convert__link"
                                                type="button"
                                                onClick={watchCreatingFromTemplateYoutubeHandler}
                                            >
                                                <span class="step-how-you-want-convert__link-triangle"></span>Watch video
                                            </button>
                                            <button
                                                class="step-how-you-want-convert__conversion-types-button"
                                                type="button"
                                                onClick={chooseTemplateHandler}
                                            >
                                                Select
                                            </button>
                                            <div class="step-how-you-want-convert__conversion-types-help-wrapper">
                                                <div ref={helpChooseElement} class="step-how-you-want-convert__conversion-types-help step-how-you-want-convert__conversion-types-help--choose">
                                                    <p>
                                                        Select a ready-made template,
                                                        it will load in the Editor right away.
                                                        Name your form, check the settings and preview it.
                                                    </p>
                                            <button class="step-how-you-want-convert__conversion-types-button step-how-you-want-convert__conversion-types-button--watch" type="button" onClick={watchCreatingFromTemplateYoutubeHandler}>Watch Video</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="step-how-you-want-convert__conversion-types-wrapper step-how-you-want-convert__conversion-types-wrapper--separator">
                                        <div class="step-how-you-want-convert__conversion-types-separator"></div>
                                    </div>

                                    <div class="step-how-you-want-convert__conversion-types-wrapper">
                                        <div class="step-how-you-want-convert__conversion-types-item">
                                            <img
                                                class="step-how-you-want-convert__conversion-types-image"
                                                width="180"
                                                height="180"
                                                src="storage/images/home-page/v1/heroSection/1-8(2).png"
                                            />
                                            <p class="step-how-you-want-convert__conversion-types-heading">
                                                    Upload
                                                <button
                                                    class="step-how-you-want-convert__button step-how-you-want-convert__button--help"
                                                    onClick={helpUploadHandler}
                                                    type="button"
                                                >
                                                    <span class="step-how-you-want-convert__button-caption">?</span>
                                                </button>
                                            </p>
                                            <p class="step-how-you-want-convert__conversion-types-description">
                                                Use your existing<br />
                                                PDF form or report
                                            </p>

                                            <button
                                                class="step-how-you-want-convert__link"
                                                type="button"
                                                onClick={watchUploadOwnPdfYoutubeHandler}
                                            >
                                                <span class="step-how-you-want-convert__link-triangle"></span>Watch video
                                            </button>

                                            <button class="step-how-you-want-convert__conversion-types-button" type="button" onClick={uploadHandler}>Select</button>
                                            <div class="step-how-you-want-convert__conversion-types-help-wrapper">
                                                <div ref={helpUploadElement} class="step-how-you-want-convert__conversion-types-help step-how-you-want-convert__conversion-types-help--upload">
                                                    <p>
                                                        Choose the form to upload, select if you want to convert it with AI, or manually.
                                                    </p>
                                                    <button
                                                        class="step-how-you-want-convert__conversion-types-button step-how-you-want-convert__conversion-types-button--watch"
                                                        type="button"
                                                        onClick={watchUploadOwnPdfYoutubeHandler}
                                                    >
                                                        Watch Video
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="step-how-you-want-convert__conversion-types-wrapper step-how-you-want-convert__conversion-types-wrapper--separator">
                                        <div class="step-how-you-want-convert__conversion-types-separator"></div>
                                    </div>

                                    <div class="step-how-you-want-convert__conversion-types-wrapper">
                                        <div class="step-how-you-want-convert__conversion-types-item">
                                            <img class="step-how-you-want-convert__conversion-types-image"
                                                width="180"
                                                height="180"
                                                src="/storage/images/home-page/v1/heroSection/1-8(3).png"/>
                                            <p class="step-how-you-want-convert__conversion-types-heading">
                                                Blank
                                                <button
                                                    class="step-how-you-want-convert__button step-how-you-want-convert__button--help"
                                                    onClick={helpBlank}
                                                    type="button"
                                                >
                                                    <span class="step-how-you-want-convert__button-caption">?</span>
                                                </button>
                                            </p>
                                            <p class="step-how-you-want-convert__conversion-types-description">
                                                Make it from scratch
                                            </p>

                                            <button class="step-how-you-want-convert__link" type="button" onClick={watchCreatingBlankApplicationYoutubeHandler}>
                                                <span class="step-how-you-want-convert__link-triangle"></span>Watch video
                                            </button>
                                    <button class="step-how-you-want-convert__conversion-types-button" type="button" onClick={createBlankApp}>Select</button>
                                            <div class="step-how-you-want-convert__conversion-types-help-wrapper">
                                                <div ref={helpBlankElement} class="step-how-you-want-convert__conversion-types-help step-how-you-want-convert__conversion-types-help--blank">
                                                    <p>
                                                        Create your own form from scratch by dropping the form fields.
                                                    </p>
                                                    <button
                                                        class="step-how-you-want-convert__conversion-types-button step-how-you-want-convert__conversion-types-button--watch"
                                                        type="button"
                                                        onClick={watchCreatingBlankApplicationYoutubeHandler}
                                                    >
                                                    Watch Video
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="step-how-you-want-convert__mobile">
                                <p class="step-how-you-want-convert__mobile-heading">
                                    Where do you want to <br /> convert your form?
                                </p>
                                <div class="step-how-you-want-convert__mobile-apple-device">
                                    <svg version="1.1" x="0px" y="0px" width="28" height="28" viewBox="0 0 28 28" class="icon-highlight">
                                        <g><line x1="14" y1="21" x2="14.01" y2="21" class="sn__icon__figure"></line>
                                            <rect id="Rectangle_479" width="28" height="28" class="st1" style="fill: none;"></rect>
                                            <path d="M20,26H8c-1.66,0-3-1.34-3-3V5c0-1.66,1.34-3,3-3h12c1.66,0,3,1.34,3,3v18C23,24.66,21.66,26,20,26z" class="sn__icon__figure">
                                            </path>
                                        </g>
                                    </svg>
                                    <p class="step-how-you-want-convert__mobile-my-apple">My Apple device</p>
                                </div>
                                <p class="step-how-you-want-convert__mobile-apple-note">
                                    Please note: Once you make a selection <br />
                                    you need to continue form conversion <br />
                                    and editing there.
                                </p>
                                <div class="step-how-you-want-convert__mobile-download-apple">
                                    <p class="step-how-you-want-convert__mobile-download-description-apple">
                                        Download Snappii<br />
                                        Mobile Forms App
                                    </p>
                                    <a href="https://apps.apple.com/us/app/snappii-mobile-forms/id1441762511" target="_blank" rel="noopener">
                                        <img src="storage/images/home-page/v1/heroSection/app-store.png"/>
                                    </a>
                                </div>
                                    <hr class="step-how-you-want-convert__mobile-hr" />
                                    <div class="step-how-you-want-convert__mobile-desktop">
                                        <svg version="1.1" x="0px" y="0px" width="28" height="28" viewBox="0 0 28 28" class="icon-highlight sn__icon"><g><line x1="9" y1="25" x2="19" y2="25" class="sn__icon__figure"></line> <line x1="14" y1="19" x2="14" y2="25" class="sn__icon__figure"></line> <rect id="Rectangle_477" width="28" height="28" class="st1" style="fill: none;"></rect> <path d="M23,19H5c-1.66,0-3-1.34-3-3V6c0-1.66,1.34-3,3-3h18c1.66,0,3,1.34,3,3v10C26,17.66,24.66,19,23,19z" class="sn__icon__figure"></path></g></svg>
                                        <p>My computer / laptop</p>
                                    </div>
                                    <p class="step-how-you-want-convert__mobile-description-desktop">Please open this wizard on the desktop</p>
                                </div>
                            </div>
                            <div class="step-how-you-want-convert__footer">
                                <button class="step-how-you-want-convert__button step-how-you-want-convert__button--back" onClick={backHandler}>
                                    Back
                                </button>
                                <button class="step-how-you-want-convert__button step-how-you-want-convert__button--cancel" onClick={cancelHandler}>
                                    Cancel
                                </button>
                            </div>

                            <div class="step-how-you-want-convert__loader-container">
                                <div class="loader">
                                    <div class="loader__spinner"></div>
                                </div>
                            </div>
                        </div>
                        )
    }
});

export type StepHowYouWantConvert = InstanceType<typeof StepHowYouWantConvert>