import { defineComponent, computed } from 'vue';
import ModalWindow from '/blocks/common/components/modal-window/modal-window.vue';
import { SignUpForm } from '/blocks/common/components/sign-up-form';

export const ModalSignUpForm = defineComponent({
    name: 'ModalSignUpForm',
    components: {
        ModalWindow,
        SignUpForm
    },
    emits: ['close'],
    props: ['modal'],
    setup(props, { emit }) {
        const modalWindowOpen = true;
        const onCloseHandler = () => {
            emit('close', {
                modal: props.modal
            });
        }

        return () => (
            <ModalWindow open={modalWindowOpen} onClose={onCloseHandler}>
                {{
                    default: () => <SignUpForm onClose={onCloseHandler}></SignUpForm>
                }}
            </ModalWindow>
        )
    }
});

export type ModalSignUpForm = InstanceType<typeof ModalSignUpForm>