import { defineComponent } from 'vue';
import { ModalManagementSystem } from '/blocks/common/components/modal-management-system';

export const ModalSystemApplication =  defineComponent({
    name: 'App',
    components: {
        ModalManagementSystem
    },
    setup() {
        return () => (
            <ModalManagementSystem></ModalManagementSystem>
        )
    }
});

export type ModalSystemApplication = InstanceType<typeof ModalSystemApplication>
