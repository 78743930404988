import axios, { AxiosRequestConfig } from 'axios';
import { addStat, setCookie, getCookie, checkEmailOnServer, getRequestParam, checkForExcludedFromRedirectPages } from './utility';
import { gapi } from 'gapi-script';
import { FaceBookAuth } from './auth/facebook-auth'


export class Dialogs {
    init() {
    }

    static _initSignUpTab() {
        document.querySelector('#sign-up-form-kp2-step-1-submit-btn')
            .addEventListener('click', (event) => {
                Dialogs.showRegistrationMsg('');
                let emailInput: HTMLInputElement = document.querySelector('#reg-fname-txt-kp2');
                let fnameInput: HTMLInputElement = document.querySelector('#reg-lname-txt-kp2');
                let lnameInput: HTMLInputElement = document.querySelector('#reg-email-txt-kp2');

                if (emailInput.checkValidity() && fnameInput.checkValidity() && lnameInput.checkValidity()) {
                    let signUpFormStep1: HTMLElement = document.querySelector('.sign-up-form-kp2-step-1');
                    let signUpFormStep2: HTMLElement = document.querySelector('.sign-up-form-kp2-step-2');
                    signUpFormStep1.style.display = 'none';
                    signUpFormStep2.style.display = 'block';
                    // yaMetricaSnConversions.reachEvent.webSiteConversions.allPages.registrationDialog.completeRegStep1();
                }
                else {
                    Dialogs.showRegistrationMsg('You need to fill all required fields for registration');
                    emailInput.reportValidity() && fnameInput.reportValidity() && lnameInput.reportValidity();
                }
            })


        const signUpFormKp2 = document.querySelector('#sign-up-form-kp2');
        signUpFormKp2.addEventListener('submit', async (event) => {
            Dialogs.showRegistrationMsg('');
            event.preventDefault();

            const registrationAgreeLicenseBox: HTMLInputElement = document.querySelector('.registration-form__agree-license-box');

            if (registrationAgreeLicenseBox.checked == false) {
                Dialogs.showRegistrationMsg('You need to agree with service license.');
                event.preventDefault();

                return false;
            }

            let isgReCaptchaUsed = false;
            let reCaptchaResponse = 'false';

            if (typeof grecaptcha !== 'undefined') {
                isgReCaptchaUsed = true;
            }

            let registrationFormReCaptchaBox: HTMLElement = document.querySelector('#registrationFormReCaptchaBox');
            let registrationRecaptchaId: number = parseInt(registrationFormReCaptchaBox.dataset.recaptcha);

            if (isgReCaptchaUsed) {
                reCaptchaResponse = grecaptcha.getResponse(registrationRecaptchaId);
                if (reCaptchaResponse.length == 0) {
                    event.preventDefault();
                    Dialogs.showRegistrationMsg('Please check the captcha box to proceed registration');
                    return false
                }
            }

            const fnameElement: HTMLInputElement = document.querySelector('#reg-fname-txt-kp2');
            let fname = fnameElement.value;

            const lnameElement: HTMLInputElement = document.querySelector('#reg-lname-txt-kp2');
            let lname = lnameElement.value;

            const companyElement: HTMLInputElement = document.querySelector('#reg-company-txt-kp2');
            let company = companyElement.value;

            const emailElement: HTMLInputElement = document.querySelector('#reg-email-txt-kp2');
            let email = emailElement.value;

            const passElement: HTMLInputElement = document.querySelector('#reg-pass-txt-kp2');
            let pass = passElement.value;

            const phoneElement: HTMLInputElement = document.querySelector('#phone-txt-kp2');
            let phone = phoneElement.value;

            const companySizeElement: HTMLInputElement = document.querySelector('#company-size-select-kp2');
            let companySize = companySizeElement.value;

            const jobTitleElement: HTMLInputElement = document.querySelector('#job-title-txt-kp2');
            let jobTitle = jobTitleElement.value;

            if ((fname == '' || lname == '' || company == '' || email == '' || pass == '' || phone == '' || companySize == '' || jobTitle == '')) {
                Dialogs.showRegistrationMsg('You need to fill all required fields for registration');
                event.preventDefault();
                return false;
            }

            let emailExists = false;

            const checkEmailResult = await checkEmailOnServer(email);

            if (checkEmailResult.indexOf('This email is already registered, but has not been verified') > -1) {
                Dialogs.showRegistrationMsg('This email address is already registered, but has not been verified. Please click <a href="#" onclick="resendActivationMessage( $(\"#login-email-txt\").val())">here</a> to resend verification email.');
                emailExists = true;
            }

            if (checkEmailResult.indexOf('This email is already registered') > -1) {
                Dialogs.showRegistrationMsg('This email is already registered, please login');
                emailExists = true;
            }

            if (emailExists) {
                let signUpFormStep1: HTMLElement = document.querySelector('.sign-up-form-kp2-step-1');
                let signUpFormStep2: HTMLElement = document.querySelector('.sign-up-form-kp2-step-2');
                signUpFormStep1.style.display = 'block';
                signUpFormStep2.style.display = 'none';

                return;
            }

            if (!emailExists) {
                const registerFormData = new FormData();
                registerFormData.append('type', 'register');
                registerFormData.append('firstname', fname);
                registerFormData.append('lastname', lname);
                registerFormData.append('email', email);
                registerFormData.append('password', pass);
                registerFormData.append('company', company);
                registerFormData.append('jobtitle', jobTitle);
                registerFormData.append('companysize', companySize);

                const countyCodeSelectElement: HTMLSelectElement = document.querySelector('#countrycode-kp2')
                registerFormData.append('phone', `(${countyCodeSelectElement.value})${phone}`);

                registerFormData.append('leadSource', 'Website');
                registerFormData.append('leadsourcedetails', 'Platform Registration');
                registerFormData.append('g-recaptcha-response', reCaptchaResponse);
                registerFormData.append('recap-new-handler', encodeURIComponent(isgReCaptchaUsed));

                const registerOpt: AxiosRequestConfig = {
                    method: 'post',
                    url: 'handlers/registration.ashx',
                    data: registerFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                };

                return axios(registerOpt)
                    .then((response) => {
                        let msg: string = response.data;
                        let openingTemplateId = getRequestParam('templateId');
                        let showTrainingInfo = null;
                        let copingTemplateId = null;

                        if (msg == 'Registration successfully.' || msg == 'Registration successfully. Need Email confirmation.') {

                            if (openingTemplateId != '') {
                                setCookie("openingTemplateId", openingTemplateId, 1);
                                window.location.reload();

                                return;
                            }

                            if (copingTemplateId != null) {
                                setCookie("copingTemplateId", copingTemplateId, 1);
                                setCookie("justtRegistered", true, 1);
                                window.location.reload();

                                return;
                            }

                            if (showTrainingInfo == true) {
                                window.location.href = '/?showTrainingInfo=1';

                                return;
                            }


                            if (msg == 'Registration successfully.') {
                                var url = getCookie("redirect");
                                setCookie("redirect", '');
                                if (!url) {
                                    url = '/mode-choice';
                                }

                                Dialogs.showRegistrationMsg('Thank you for registering!');
                                window.location.href = url;

                                return;

                            } else if (msg == 'Registration successfully. Need Email confirmation.') {
                                let signUpFormStep2: HTMLElement = document.querySelector('.sign-up-form-kp2-step-2');
                                signUpFormStep2.style.display = 'none';

                                Dialogs.showRegistrationMsg('<div style="color: green">Thank you for registering!<br /><br /> ' +
                                    "We've sent a verification email to <br />" + email + ".<br /><br /> Follow the instructions in the email to activate your user account.<br /></div>");
                             
                                //Snappii.ElementBlocker().unblock();
                            }

                        } else {
                            // Snappii.ElementBlocker().unblock();
                            Dialogs.showRegistrationMsg('Error. ' + msg)
                            return false;
                        }

                    })
                    .catch((error) => {

                    })
            }
        });
    }

    static _initLoginTab() {
        document.querySelector('.login-form__forgot-password-button').addEventListener('click', () => {
            const loginFormEmail: HTMLInputElement = document.querySelector('#login-email-txt-kp2');
            Dialogs.forgotPasswordByEmail(loginFormEmail.value);
        });

        document.querySelector('#login-form-kp2')
            .addEventListener('submit', () => {
                //TODO ???
                let openingTemplateId = null;
                let copingTemplateId = null;
                let showTrainingInfo = false;
                let selectedPurchase = null;
                //

                event.preventDefault();
                Dialogs.showLoginMsg('');

                const loginForm: HTMLFormElement = document.querySelector('.login-form');
                const loginFormData = new FormData(loginForm);
                loginFormData.append('type', 'login');

                //TODO ???
                let sessionKey = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                loginFormData.append('sessionKey', sessionKey);

                const loginOpt: AxiosRequestConfig = {
                    method: 'post',
                    url: 'handlers/registration.ashx',
                    data: loginFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                };

                axios(loginOpt)
                    .then((response) => {
                        if (response.data === true) {
                            addStat('LoginFromHomePage');

                            if (openingTemplateId != null) {
                                setCookie("openingTemplateId", openingTemplateId, 1);
                                window.location.reload();

                                return;
                            }

                            if (copingTemplateId != null) {
                                setCookie("copingTemplateId", copingTemplateId, 1);
                                window.location.reload();

                                return;
                            }

                            if (showTrainingInfo == true) {
                                window.location.href = '/?showTrainingInfo=1';

                                return;
                            }

                            //TODO ???
                            //if (selectedPurchase != null) {
                            //    showAfterPurchaseDialog(selectedPurchase);
                            //}

                            setCookie('login', 1, 1);
                            if (getCookie('redirect') != '') {
                                window.location.href = getCookie('redirect');
                                setCookie('redirect', '');

                                return;
                            } else {
                                if (!checkForExcludedFromRedirectPages()) {
                                    window.location.href = 'my-apps';

                                    return;
                                } else {
                                    location.reload();
                                    return;
                                }                         
                            }                         
                        }

                        if (response.data === 'notActivated') {
                            const msg: string = ``;
                            Dialogs.showLoginMsg("Error! You need to verify your email address to login. <br /> Please check your email for the message from us and click the verification link.<br /> <br />  Click <a href='#' onclick='resendActivationMessage( $(\"#login-email-txt-kp2\").val())'>here</a> to get the verification email again.");

                            return;
                        }

                        if (response.data !== '') {
                            Dialogs.showLoginMsg(`Error: ${response.data}`);
                        }

                    })
                    .catch((error) => {
                    })

            })
    }

    static googleLogin() {
        gapi.auth2.getAuthInstance().signIn();
    }

    static facebookLogin() {
        FaceBookAuth.facebookLogin();
    }

    static login() {
        Dialogs._openLoginRegTabKp2('login_tab_btn_kp2');
    }

    static signUp() {
        Dialogs._openLoginRegTabKp2('sign_up_tab_btn_kp2');
    }

    static showRegistrationMsg(msg: string) {
        document.querySelector('.registration_error_msg_box_kp2').innerHTML = msg;
    }

    static showLoginMsg(msg: string) {
        document.querySelector('.login_error_msg_box_kp2').innerHTML = msg;
    }


    static sendInfoToSnappii(emailFrom, emailTo, content) {
        const infoFormData = new FormData();
        infoFormData.append('action', 'supportmail');
        infoFormData.append('email', emailFrom);
        infoFormData.append('emailto', emailTo);
        infoFormData.append('content', content);

        const infoFormOpt: AxiosRequestConfig = {
            method: 'post',
            url: 'handlers/supportHandler.ashx',
            data: infoFormData,
            headers: { "Content-Type": "multipart/form-data" },
        };


        axios(infoFormOpt)
            .then((response) => {
                debugger;
            })
            .catch((error) => {

            })

    }

    static forgotPasswordByEmail(email: string) {
        if (Dialogs._checkEmail(email) === false) {
            let alertText = 'Email is incorrect.';
            Dialogs.showLoginMsg(alertText);
            return;
        }

        const form = new FormData();
        form.append('type', 'forgot');
        form.append('email', email);

        const forgotOpt: AxiosRequestConfig = {
            method: 'post',
            url: 'handlers/registration.ashx',
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
        };

        axios(forgotOpt)
            .then((response) => {
                var alertText = '';
                //ga('send', 'event', 'Login dialog', 'Forgot password', msg);

                if (response.data === true) {
                    alertText = 'Instructions to reset your password have been emailed to you.';
                } else if (response.data.trim() == 'notActivated') {
                    alertText = "Error! You need to verify your email address to login. <br /> Please check your email for the message from us and click the verification link.<br /> <br />  Click <a href='#' onclick='resendActivationMessage( $(\"#login-email-txt-kp2\").val())'>here</a> to get the verification email again.";
                } else {
                    alertText = response.data;
                }

                Dialogs.showLoginMsg(alertText);
            })
            .catch((error) => {
                console.log('err')
            })
    }

    static _checkEmail(email) {
        const emailPattern = /^([\w]+)(.[\w]+)*@([\w-]+\.)+([A-Za-z])+$/;
        if (email === '') {
            return false;
        }

        return emailPattern.test(email.trim());
    }

    static _closeLoginRegTabKp2() {
        const shadowBox = document.querySelector('.new_login_dialog_shadow_box_kp2') as HTMLElement;
        shadowBox.style.display = 'none';

        document.querySelector('body').classList.remove('body_modal_disable');
        //ga('send', 'event', 'Login dialog', 'close');
    }

    static _openLoginRegTabKp2(targe_tab?: string) {
        let targe_tab_selector = '#login_tab_btn_kp2';

        if (targe_tab !== undefined) {
            targe_tab_selector = '#' + targe_tab;

            if (targe_tab === 'sign_up_tab_btn_kp2') {
                //yaMetricaSnConversions.reachEvent.webSiteConversions.allPages.registrationDialog.openRegDialod();
            }
        }

        document.querySelectorAll('.new_login_dialog_shadow_box_kp2 .login_sign_up_box_tab_btn')
            .forEach((element) => element.classList.remove('active_tab'));

        document.querySelectorAll('.new_login_dialog_shadow_box_kp2 .new_login_dialog_box_tab')
            .forEach((element) => element.classList.remove('active_tab_content'));

        document.querySelector(targe_tab_selector)
            .classList.add('active_tab');

        document.querySelector(targe_tab_selector + '_content').classList.add('active_tab_content');

        let shadowBox = document.querySelector('.new_login_dialog_shadow_box_kp2') as HTMLElement;
        shadowBox.style.display = 'block';

        document.querySelector('body').classList.add('body_modal_disable');

        //ga('send', 'event', 'Login dialog', 'Open tab:', $.trim($('.login_sign_up_box_tab_btn.active_tab').text()));
    }
}