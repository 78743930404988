import { getRequestParam } from './../utility';

export class LiveChat {
    async init() {
        // Misc Options
        window['lhnAccountN'] = 7095; //LiveHelpNow account #
        window['lhnButtonN'] = 3600; //Button #
        window['lhnVersion'] = 5.3; //LiveHelpNow version #
        // Chat Options
        window['lhnJsHost'] = (("https:" == document.location.protocol) ? "https://" : "http://"); //to force secure chat replace this line with: var lhnJsHost = 'https://';
        window['lhnInviteEnabled'] = 0; //Invite visitor to chat automatically
        if (location.pathname == '/') {
            window['lhnInviteEnabled'] = 1;
        }
        window['lhnInviteChime'] = 0; //1 = disable invite beep sound,0 = keep invite beep sound enabled
        window['lhnWindowN'] = 0; //Chat window #, leave 0 to open default window setup for your account
        window['lhnDepartmentN'] = 0; //Department #, leave 0 to not route by department
        window['lhnCustomInvitation'] = 1; //change to 1 to use custom invitation, see this article for customization instructions: http://help.livehelpnow.net/article.aspx?cid=1&aid=1739

        window['lhnCustom1'] = 'Not registered';
        window['lhnCustom2'] = 'Unknown';
        //if (currentUserInfo) {
        //    lhnCustom2 = currentUserInfo.email;
        //    lhnCustom1 = currentUserInfo.name;
        //}

        window['lhnCustom3'] = ''; //Custom3 feed value please use encodeURIComponent() function to encode your values
        window['lhnTrackingEnabled'] = 't'; //change to 'f' to disable visitor tracking
        // Options End
        const lhnScriptSrc = window['lhnJsHost'] + 'www.livehelpnow.net/lhn/scripts/livehelpnow.aspx?lhnid=' + window['lhnAccountN'] + '&iv=' + window['lhnInviteEnabled'] + '&d=' + window['lhnDepartmentN'] + '&ver=' + window['lhnVersion'] + '&rnd=' + Math.random();
        const lhnScript = document.createElement('script');
        lhnScript.type = "text/javascript";
        lhnScript.src = lhnScriptSrc;

        if (window.addEventListener) {
            window.addEventListener('load', () => {
                let lhnContainer = document.querySelector('#lhnContainer');
                lhnContainer.appendChild(lhnScript);
            }, false);
        }

        if (document.readyState != 'loading') {
            this.initEvents();
        } else {
            document.addEventListener('DOMContentLoaded', this.initEvents);
        }
    }

    async initEvents() {
        if (getRequestParam(`chat`) == "true") {
            try {
                setTimeout('OpenLHNChat()', 8000);
            } catch (e) {

            };
        }

        const startChat: HTMLElement = document.querySelector(`#start-chat`);
        if (startChat !== null) {
            startChat.addEventListener('click', () => {
                window['OpenLHNChat']();
                return false;
            })
        }

        const openFaq: HTMLElement = document.querySelector(`#open-faq`);
        if (openFaq !== null) {
            openFaq.addEventListener('click', () => {
                window.open('faqs/');
                return false;
            })
        }

        const closeInvitation: HTMLElement = document.querySelector(`#close-invitation`);
        if (closeInvitation !== null) {
            closeInvitation.addEventListener('click', () => {
                if (typeof window['CloseLHNInvite'] !== 'undefined') {
                    window['CloseLHNInvite']();
                    return false;
                }
                else {
                    const zSmenu: HTMLElement = document.querySelector('#Zsmenu');
                    if (zSmenu !== null) {
                        zSmenu.style.display = 'none';
                    }    
                }
            })
        }
    }
} 