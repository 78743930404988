import { defineComponent, computed } from 'vue';
import ModalWindow from '/blocks/common/components/modal-window/modal-window.vue';
import { RegisterForFreeClassForm } from '/blocks/common/components/register-for-free-class-form';

export const ModalRegisterForFreeClassForm = defineComponent({
    name: 'ModalRegisterForFreeClassForm',
    components: {
        ModalWindow,
        RegisterForFreeClassForm
    },
    emits: ['close'],
    props: ['modal'],
    setup(props, { emit }) {
        const modalWindowOpen = true;
        const onCloseHandler = () => {
            emit('close', {
                modal: props.modal
            });
        }

        return () => (
            <ModalWindow open={modalWindowOpen} onClose={onCloseHandler}>
                {{
                    default: () => <RegisterForFreeClassForm onClose={onCloseHandler}></RegisterForFreeClassForm >
                }}                  
            </ModalWindow>
        )
    }
});

export type ModalRegisterForFreeClassForm = InstanceType<typeof ModalRegisterForFreeClassForm>