import { defineComponent} from 'vue';
import { useStore } from 'vuex';

export const StepFormConverterAI = defineComponent({
    name: 'StepFormConverterAI',
    emits: ['cancel', 'skip', 'back', 'nextStep'],
    components: {
    },
    setup(props, { emit }) {
        const store = useStore();
        
        function cancelHandler() {
            emit('cancel')
        };

        function nextHandler() {
            emit('nextStep');
        }

        function backHandler() {
            emit('back');
        }

        function skipHandler() {
            emit('skip');
        }

        function dotSelectHandler(event){
            if (event.srcElement.classList.contains('active')) {
                return;
            }

            if (!event.srcElement.classList.contains('step-form-converter-ai__dots-item')) {
                return
            }

            const dotElementIndex = Array.from(event.currentTarget.children).indexOf(event.srcElement);
            const dotActiveElement = event.currentTarget.querySelector('.step-form-converter-ai__dots-item--active');
            const dotSelectedElementIndex = Array.from(event.currentTarget.children).indexOf(dotActiveElement);

            emit('nextStep', { stepIndex: dotElementIndex - dotSelectedElementIndex })
        }

        function watchVideoHandler(){
            store.dispatch('modalManagementSystem/addModalWindow', {
                content: 'Youtube',
                url: 'https://www.youtube.com/embed/q1DlvPIPCqs',
                autoplay: true
            })
        }

        return () => (
            <div class="step-form-converter-ai">
                <div class="step-form-converter-ai__header">
                    <button class="step-form-converter-ai__button step-form-converter-ai__button--skip" onClick={skipHandler}>skip</button>
                    <div class="step-form-converter-ai__dots-items" onClick={dotSelectHandler}>
                        <div class="step-form-converter-ai__dots-item"></div>
                        <div class="step-form-converter-ai__dots-item step-form-converter-ai__dots-item--active"></div>
                    </div>
                    <button class="step-form-converter-ai__button step-form-converter-ai__button--header-cancel" onClick={cancelHandler}>
                        <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                    </button>
                </div>
                <div class="step-form-converter-ai__body">
                    <p class="step-form-converter-ai__description">
                        The Form Converter uses AI to find fields
                        in your forms, and it will do most of the work for you.
                        You fill in manually the rest.
                    </p>
                    <button class="step-form-converter-ai__link" type="button" onClick={watchVideoHandler}>
                        <span class="step-form-converter-ai__link-triangle"></span>Watch video</button>
                        <picture class="step-form-converter-ai__image-container">
                            <source srcset="storage/images/blocks/common/convert-my-form/form-convert-flow/step-form-converter-ai/fc-ai.avif" type="image/avif"/>
                            <source srcset="storage/images/blocks/common/convert-my-form/form-convert-flow/step-form-converter-ai/fc-ai.webp" type="image/webp"/>
                            <img
                                class="step-form-converter-ai__image"
                                src="storage/images/blocks/common/convert-my-form/form-convert-flow/step-form-converter-ai/fc-ai.png"
                            />
                        </picture>
                </div>
                <div class="step-form-converter-ai__footer">
                    <button class="step-form-converter-ai__button step-form-converter-ai__button--back" onClick={backHandler}>
                        Back
                    </button>
                    <button class="step-form-converter-ai__button step-form-converter-ai__button--next" onClick={nextHandler}>
                        Next
                    </button>
                            <button class="step-form-converter-ai__button step-form-converter-ai__button--cancel" onClick={cancelHandler}>
                        Cancel
                    </button>
                </div>
            </div>
        )
    },
})

export type StepFormConverterAI = InstanceType<typeof StepFormConverterAI>