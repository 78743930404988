import { defineComponent, computed } from 'vue';
import ModalWindow from '/blocks/common/components/modal-window/modal-window.vue';

export const ModalYoutube = defineComponent({
    name: 'ModalYoutube',
    components: {
        ModalWindow
    },
    emits: ['close'],
    props: ['modal'],
    setup(props, { emit }) {
        const modalWindowOpen = true;
        const url = computed<string>(() => {
            if (props.modal.autoplay === undefined || props.modal.autoplay === false) {
                return props.modal.url
            }

            return `${props.modal.url}?autoplay=1`
        })

        function onCloseHandler(){
            emit('close', {
                modal: props.modal
            });
        };

        return () => (
            <ModalWindow open={modalWindowOpen} onClose={onCloseHandler}>
                <div class="dialog-youtube">
                    <div class="dialog-youtube__layout">
                        <div class="dialog-youtube__body">
                            <iframe
                                class="dialog-youtube__frame"
                                src={url.value}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            >
                            </iframe>
                        </div>
                        <div class="dialog-youtube__footer">
                                <button class="dialog-youtube__button" onClick={onCloseHandler}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </ModalWindow>
        )
    }
});

export type ModalYoutube = InstanceType<typeof ModalYoutube>