import { defineComponent, reactive} from 'vue';
import { useStore } from "vuex";
import { RecaptchaVersion2 } from '/blocks/common/components/recaptcha-version-2';
import { CountryCode } from './components/country-code';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { setCookie, getCookie, checkEmailOnServer, getRequestParam, checkForExcludedFromRedirectPages } from '/blocks/common/components/utility';
import axios, { AxiosRequestConfig } from 'axios';
import { gapi } from 'gapi-script';
import { FaceBookAuth } from '/blocks/common/components/auth/facebook-auth';

export const SignUpForm = defineComponent({
        name: 'SignUpForm',
        components: {
            RecaptchaVersion2,
            CountryCode
        },
        emits: ['close'],
        setup(props, { emit }) {
            const store = useStore();
            const state = reactive({
                step: 'step1',
                firstStepErrors: '',
                secondStepErrors: '',
                recaptchaResponse: null,
                userInfo: {
                    fullName: null,
                    email: null,
                    company: null,
                    companySize: '',
                    jobTitle: null,
                    password: null,
                    confirmPassword: null,
                    firstName: null,
                    lastName: null,
                    countryCode: '1',
                    phone: null
                }
            });

            const close = () => {
                emit('close');
            };

            const onSubmitFirstStep = async (event) => {
                event.preventDefault();
                const checkEmailResult = await checkEmailOnServer(state.userInfo.email);
  
                switch (checkEmailResult) {
                    case 'This email is already registered, but has not been verified':
                        state.firstStepErrors = `This email address is already registered, but has not been verified. Please click <a href="#" onclick="resendActivationMessage( $(\"#login-email-txt\").val())">here</a> to resend verification email.`
                        break;
                    case 'This email is already registered':
                        state.firstStepErrors = `<p>This email is already registered, please login</p>`;
                        break;
                    default:
                        state.step = 'step2';
                }
            };

            const goToLoginForm = () => {
                emit('close');

                store.dispatch('modalManagementSystem/addModalWindow', {
                    content: 'SignInForm',
                });         
            };

            const _validation = async () => {
                if (state.recaptchaResponse === null) {
                    state.secondStepErrors = 'Please check the captcha box to proceed registration';
                    return false;
                }

                if (state.userInfo.password === null || state.userInfo.confirmPassword === null) {
                    return false;
                }

                if (state.userInfo.password !== state.userInfo.confirmPassword) {
                    state.secondStepErrors = `Passwords does not match`;
                    return false;
                }

                return true;
            }

            const onSubmitHandler = async (event) => {
                event.preventDefault();

                const validationResult = await _validation();
                if (validationResult === false) {
                    return;
                }

                const registerFormData = new FormData();
                registerFormData.append('type', 'register');
                registerFormData.append('firstname', state.userInfo.firstName);
                registerFormData.append('lastname', state.userInfo.lastName);
                registerFormData.append('email', state.userInfo.email);
                registerFormData.append('password', state.userInfo.password);
                registerFormData.append('company', state.userInfo.company);
                registerFormData.append('companysize', state.userInfo.companySize);
                registerFormData.append('jobtitle', state.userInfo.jobTitle);
                registerFormData.append('phone', `(${state.userInfo.countryCode})${state.userInfo.phone}`);

                registerFormData.append('leadSource', 'Website');
                registerFormData.append('leadsourcedetails', 'Platform Registration');
                registerFormData.append('g-recaptcha-response', state.recaptchaResponse);
                registerFormData.append('recap-new-handler', encodeURIComponent(true));

                const registerOpt: AxiosRequestConfig = {
                    method: 'post',
                    url: 'handlers/registration.ashx',
                    data: registerFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                };


                return axios(registerOpt)
                    .then((response) => {
                        let msg: string = response.data;
                        let openingTemplateId = getRequestParam('templateId');
                        let showTrainingInfo = null;
                        let copingTemplateId = null;

                        if (msg == 'Registration successfully.' || msg == 'Registration successfully. Need Email confirmation.') {
                            if (openingTemplateId != '') {
                                setCookie('openingTemplateId', openingTemplateId, 1);
                                window.location.reload();

                                return;
                            }

                            if (copingTemplateId != null) {
                                setCookie('copingTemplateId', copingTemplateId, 1);
                                setCookie('justtRegistered', true, 1);
                                window.location.reload();

                                return;
                            }

                            if (showTrainingInfo == true) {
                                window.location.href = '/?showTrainingInfo=1';

                                return;
                            }

                            if (msg == 'Registration successfully.') {
                                var url = getCookie("redirect");
                                setCookie("redirect", '');
                                if (!url) {
                                    url = '/mode-choice';
                                }

                                state.secondStepErrors = 'Thank you for registering!';
                                window.location.href = url;

                                return;

                            } else if (msg == 'Registration successfully. Need Email confirmation.') {

                                state.secondStepErrors = '<div style="color: green">Thank you for registering!<br /><br /> ' +
                                    "We've sent a verification email to <br />" + state.userInfo.email + ".<br /><br /> Follow the instructions in the email to activate your user account.<br /></div>";
                            }

                            state.step = 'step3'
                        } else {
                            state.secondStepErrors = `Error. ${msg}`;
                            return false;
                        }
                    })
                    .catch((error) => {

                    })
            };

            const rules: any = {
                fullName: { required },
                email: {required, email} 
            };

            const v$ = useVuelidate(rules, state.userInfo)
            const onVerifyRecaptcha = (event) => {
                state.recaptchaResponse = event.response;
            };

            const signUpWithGoogle = () => {
                gapi.auth2.getAuthInstance().signIn();
            };

            const signUpWithFacebook = () => {
                FaceBookAuth.facebookLogin();
            };



            return () => (
                <div class="sign-up-form">
                    <div class="sign-up-form__layout">
                        {
                            (state.step === 'step1')
                                ?
                                   <div class="sign-up-form__form sign-up-form__form--step-1">
                                        <button class="sign-up-form__button sign-up-form__button--step-1-close" onClick={close} type="button">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M18 2L2 18" stroke="#757575" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M2 2L18 18" stroke="#757575" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                        </button>
                                        <p class="sign-up-form__heading-2">Sign Up</p>
                                        <p class="sign-up-form__text sign-up-form__text--have-an-account">Already have an account?
                                            <button class="sign-up-form__link" onClick={goToLoginForm}>Log In</button>
                                        </p>
                                        <button class="sign-up-form__button sign-up-form__button--google" onClick={signUpWithGoogle}>
                                            <svg class="sign-up-form__button-icon" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                                <circle cx="18" cy="18" r="17" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.56 18.25C28.56 17.47 28.49 16.72 28.36 16H18V20.255H23.92C23.665 21.63 22.89 22.795 21.725 23.575V26.335H25.28C27.36 24.42 28.56 21.6 28.56 18.25Z" fill="#4285F4" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9999 28.9998C20.9699 28.9998 23.4599 28.0148 25.2799 26.3348L21.7249 23.5748C20.7399 24.2348 19.4799 24.6248 17.9999 24.6248C15.1349 24.6248 12.7099 22.6898 11.8449 20.0898H8.16992V22.9398C9.97992 26.5348 13.6999 28.9998 17.9999 28.9998Z" fill="#34A853" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.845 20.0896C11.625 19.4296 11.5 18.7246 11.5 17.9996C11.5 17.2746 11.625 16.5696 11.845 15.9096V13.0596H8.17C7.425 14.5446 7 16.2246 7 17.9996C7 19.7746 7.425 21.4546 8.17 22.9396L11.845 20.0896Z" fill="#FBBC05" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9999 11.375C19.6149 11.375 21.0649 11.93 22.2049 13.02L25.3599 9.865C23.4549 8.09 20.9649 7 17.9999 7C13.6999 7 9.97992 9.465 8.16992 13.06L11.8449 15.91C12.7099 13.31 15.1349 11.375 17.9999 11.375Z" fill="#EA4335" />
                                            </svg>
                                            <span class="sign-up-form__button-text">Sign Up with Google</span>
                                        </button>
                                        <button class="sign-up-form__button sign-up-form__button--facebook" onClick={signUpWithFacebook}>
                                            <svg class="sign-up-form__button-icon" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0001 1C27.3966 1 35.0003 8.6037 35.0003 18.0001C35.0003 26.4794 28.7953 33.5078 20.6583 34.797V22.9147H24.5993L24.5994 22.9138H24.6147L25.372 17.9992H20.6583V14.8155C20.6583 13.5319 21.264 12.2764 23.1477 12.1653C23.2323 12.1606 23.3195 12.1583 23.4093 12.1583H25.5575V12.1573H25.5729V7.96908C25.5729 7.96908 23.6256 7.64453 21.771 7.64453C21.5706 7.64453 21.3738 7.6508 21.1807 7.66329C17.628 7.88667 15.3419 10.2092 15.3419 14.2601V17.9992H11.03V22.9138H11.0301V22.9147H15.3419V34.7984C15.3419 34.7984 15.3419 34.7984 15.3419 34.7984V34.7994C7.21278 33.5167 1 26.4848 1 18.0001C1 8.6037 8.6037 1 18.0001 1Z" fill="white" />
                                            </svg>
                                            <span class="sign-up-form__button-text">Sign Up with Facebook</span>
                                        </button>
                                        <hr class="sign-up-form__horizontal-separator" />
                                        <p class="sign-up-form__text sign-up-form__text--with-email">or sign up with email:</p>
                                        <form class="sign-up-form__first-step-form" onSubmit={onSubmitFirstStep}>
                                            <div class="sign-up-form__form-group sign-up-form__form-group--name">
                                                <div class="sign-up-form__form-element">
                                                    <label class="sign-up-form__label" for="username">First name *</label>
                                                <input
                                                    class="sign-up-form__input"
                                                    type="text"
                                                    required
                                                    value={state.userInfo.firstName}
                                                    onInput={(event: any) => state.userInfo.firstName = event.target.value}
                                                />
                                                </div>
                                                <div class="sign-up-form__form-element">
                                                    <label class="sign-up-form__label" for="username">Last name *</label>
                                                    <input
                                                        class="sign-up-form__input"
                                                        type="text"
                                                        required
                                                        value={state.userInfo.lastName}
                                                        onInput={(event: any) => state.userInfo.lastName = event.target.value}
                                                    />
                                                </div>
                                            </div>
                                            <div class="sign-up-form__form-element">
                                                <label class="sign-up-form__label" for="username">Email *</label>
                                                <input
                                                    class="sign-up-form__input"
                                                    type="email"
                                                    id="username"
                                                    required
                                                    value={state.userInfo.email}
                                                    onInput={(event: any) => state.userInfo.email = event.target.value}
                                                />
                                            </div>
                                        <div class="sign-up-form__first-step-errors" innerHTML={state.firstStepErrors}></div>
                                            <button class="sign-up-form__button sign-up-form__button--create-account">
                                                <span class="sign-up-form__button-text">Create Account</span>
                                            </button>
                                            <p class="sign-up-form__text">*required field</p>
                                        </form>
                                    </div>
                                :  <div class="sign-up-form__form sign-up-form__form--step-2">
                                    <button class="sign-up-form__button sign-up-form__button--step-1-close" onClick={close} type="button">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M18 2L2 18" stroke="#757575" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M2 2L18 18" stroke="#757575" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    <p class="sign-up-form__heading-2">Sign Up</p>
                                    {
                                        (state.step !== 'step3') ?
                                            <form class="sign-up-form__first-step-form" onSubmit={onSubmitHandler}>
                                                <div class="sign-up-form__form-element">
                                                    <label class="sign-up-form__label" for="username">Job title *</label>
                                                    <input
                                                        class="sign-up-form__input"
                                                        type="text"
                                                        onInput={(event: any) => state.userInfo.jobTitle = event.target.value}
                                                        required
                                                        value={state.userInfo.jobTitle}
                                                    />
                                                </div>
                                                <div class="sign-up-form__form-group sign-up-form__form-group--company">
                                                    <div class="sign-up-form__form-element">
                                                        <label class="sign-up-form__label" for="company">Company *</label>
                                                        <input class="sign-up-form__input"
                                                            onInput={(event: any) => state.userInfo.company = event.target.value}
                                                            type="text"
                                                            name="company"
                                                            required
                                                            value={state.userInfo.company}
                                                        />
                                                    </div>
                                                    <div class="sign-up-form__form-element">
                                                        <label class="sign-up-form__label" for="companySize">Company size *</label>
                                                        <select
                                                            class="sign-up-form__input"
                                                            required
                                                            value={state.userInfo.companySize}
                                                            onChange={(event: any) => state.userInfo.companySize = event.target.value}
                                                        >
                                                            <option value="" selected disabled hidden>Select size</option>
                                                            <option value="501+">501+</option>
                                                            <option value="101-500">101-500</option>
                                                            <option value="51-100">51-100</option>
                                                            <option value="11-50">11-50</option>
                                                            <option value="1-10">1-10</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="sign-up-form__form-group sign-up-form__form-group--phone">
                                                    <div class="sign-up-form__form-element">
                                                        <label class="sign-up-form__label" for="countryCode">Phone *</label>
                                                        <CountryCode
                                                            class="sign-up-form__input"
                                                            onUpdate:modelValue={(value) => { state.userInfo.countryCode = value; }}
                                                            modelValue={state.userInfo.countryCode}
                                                        />
                                                    </div>
                                                    <div class="sign-up-form__form-element">
                                                        <label class="sign-up-form__label" for="phone"></label>
                                                        <input
                                                            class="sign-up-form__input"
                                                            value={state.userInfo.phone}
                                                            onInput={(event: any) => state.userInfo.phone = event.target.value}
                                                            type="text"
                                                            name="phone"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="sign-up-form__form-group sign-up-form__form-group--password">
                                                    <div class="sign-up-form__form-element">
                                                        <label class="sign-up-form__label" for="countryCode">Password *</label>
                                                        <input
                                                            class="sign-up-form__input"
                                                            type="password"
                                                            name="password"
                                                            onInput={(event: any) => state.userInfo.password = event.target.value}
                                                            required
                                                            value={state.userInfo.password}
                                                        />
                                                    </div>
                                                    <div class="sign-up-form__form-element">
                                                        <label class="sign-up-form__label" for="phone">Confirm password *</label>
                                                        <input
                                                            class="sign-up-form__input"
                                                            type="password"
                                                            name="confirmPassword"
                                                            onInput={(event: any) => state.userInfo.confirmPassword = event.target.value}
                                                            required
                                                            value={state.userInfo.confirmPassword}
                                                        />
                                                    </div>
                                                </div>
                                                <p class="sign-up-form__text">
                                                    By joining, you agree with <a class="sign-up-form__link" href="/terms-of-service">TOS</a> and <a class="sign-up-form__link" href="/policy">Privacy Policy</a>.
                                                    You agree to receive Snappii emails.
                                                </p>
                                                <RecaptchaVersion2 onVerify={onVerifyRecaptcha} response={state.recaptchaResponse}></RecaptchaVersion2>
                                                <div class="sign-up-form__second-step-errors" innerHTML={state.secondStepErrors}></div>
                                                <button class="sign-up-form__button sign-up-form__button--create-account">
                                                    <span class="sign-up-form__button-text">Finish Registration</span>
                                                </button>
                                                <p class="sign-up-form__text">*required field</p>
                                            </form>
                                            : <div innerHTML= {state.secondStepErrors}></div>
                                    }
                                </div>
                        }
                        <div class="sign-up-form__pros">
                            <button class="sign-up-form__button sign-up-form__button--close" onClick={close} type="button">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M18 2L2 18" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2 2L18 18" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                            <ul class="sign-up-form__benefits-list">
                                <li class="sign-up-form__benefits-item">
                                    <svg class="sign-up-form__benefits-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" fill="#69D631" />
                                        <path d="M22 4L12 14.01L9 11.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span class="sign-up-form__benefits-text">
                                        Free Forever Account
                                    </span>
                                </li>
                                <li class="sign-up-form__benefits-item">
                                    <svg class="sign-up-form__benefits-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" fill="#69D631" />
                                        <path d="M22 4L12 14.01L9 11.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span class="sign-up-form__benefits-text">
                                        No Credit Card Required
                                    </span>
                                </li>
                                <li class="sign-up-form__benefits-item">
                                    <svg class="sign-up-form__benefits-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" fill="#69D631" />
                                        <path d="M22 4L12 14.01L9 11.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span class="sign-up-form__benefits-text">
                                        Over 28,000 Apps built<br />
                                        and trusted by companies like:
                                    </span>
                                </li>
                            </ul>
                            <ul class="sign-up-form__clients-items">
                                <li class="sign-up-form__clients-item">
                                    <img class="sign-up-form__clients-logo" src="storage/images/blocks/common/components/sign-up-form/clients-ge.svg" alt=""/>
                                </li>
                                <li class="sign-up-form__clients-item">
                                    <img class="sign-up-form__clients-logo" src="storage/images/blocks/common/components/sign-up-form/clients-allianz.svg" alt=""/>
                                </li>
                                <li class="sign-up-form__clients-item">
                                    <img class="sign-up-form__clients-logo" src="storage/images/blocks/common/components/sign-up-form/clients-driscolls.svg" alt=""/>
                                </li>
                                <li class="sign-up-form__clients-item">
                                    <img class="sign-up-form__clients-logo" src="storage/images/blocks/common/components/sign-up-form/clients-hyster.svg" alt=""/>
                                </li>
                                <li class="sign-up-form__clients-item">
                                    <img class="sign-up-form__clients-logo" src="storage/images/blocks/common/components/sign-up-form/clients-yale.svg" alt=""/>
                                </li>
                                <li class="sign-up-form__clients-item">
                                    <img class="sign-up-form__clients-logo" src="storage/images/blocks/common/components/sign-up-form/clients-nationalease.svg" alt=""/>
                                </li>
                                <li class="sign-up-form__clients-item">
                                    <img class="sign-up-form__clients-logo" src="storage/images/blocks/common/components/sign-up-form/clients-AFSC.svg" alt=""/>
                                </li>
                            </ul>
                            <p class="sign-up-form__pros-text sign-up-form__pros-text--contact">
                                For group sales please contact<br />
                                <a class="sign-up-form__pros-link" href="mailto:sales@snappii.com">sales@snappii.com</a>
                            </p>
                            <picture class="sign-up-form__image-container">
                                <img class="sign-up-form__image" src="storage/images/blocks/common/components/sign-up-form/mobile-phones.png" alt=""/>
                            </picture>
                        </div>
                    </div>
                </div>
            );
        },
    })

export type SignUpForm = InstanceType<typeof SignUpForm>