
<template>
    <div v-if="open" v-cloak>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper" v-on:click.self="onClickonWrapper">
                    <div class="modal-container">
                        <div class="modal-container__modal-body">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { defineComponent, watch, onUnmounted, onDeactivated, onMounted} from 'vue';

    export default defineComponent({
        name: 'ModalWindow',
        props: {
            'open': Boolean
        },
        components: {
        },
        emits: ['close'],
        setup(props, { emit }) {  
            const onClickonWrapper = () => {
                emit('close');
            }

            watch(() => props.open, (newValueOpen, prevValueOpen) => {
                if (prevValueOpen === true && newValueOpen === false) {
                    emit('close');
                }
            }, {
                immediate: true
            })

            return {
                onClickonWrapper
            }
        }
    })
</script>