import { defineComponent, ref, computed, watch, reactive, onMounted, toRef, watchEffect } from 'vue';
import { useStore } from "vuex";

export const RecaptchaVersion2 = defineComponent({
    name: 'RecaptchaVersion2',
    emits: ['verify'],
    props: {
        'apiLoaded': Boolean,
        'siteKey': String,
        'response': String
    },
    components: {
    },
    setup(props, { emit }) {
        const store = useStore();
        const root = ref(null);
        const state = reactive({
            isInitinitialized: false,
            isMounted: false,
            recaptchaId: null,
            reCaptchaSiteKey: store.state.recaptcha.reCaptchaSiteKey,
            result: null,
            response: null
        });

        const response = toRef(props, 'response')

        const successCallback = (response) => {
            emit('verify', { response: response });
        };

        const expiredCallback = () => {
            emit('verify', { response: null});
        };
        watch(
            [() => store.state.recaptcha.recaptchaLoaded, () => state.isMounted],
            ([newValueRecaptchaLoaded, prevValueRecaptchaLoaded], [newValueIsMounted, prevValueIsMounted]) => {
                if (newValueRecaptchaLoaded === true && newValueIsMounted === true) {

                state.recaptchaId = grecaptcha.render(root.value, {
                    'sitekey': state.reCaptchaSiteKey,
                    'callback': successCallback,
                    'expired-callback': expiredCallback
                });
            }
        }, {
            immediate: true
        });

        onMounted(() => {
            state.isMounted = true
        })

        return () => (
            <div ref={root}></div>
        )
    },
})

export type RecaptchaVersion2 = InstanceType<typeof RecaptchaVersion2>