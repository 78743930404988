
    import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
    import { useStore} from 'vuex';
    import * as ModalWindow from '/blocks/common/components/modal-window/modal-window.vue';
    import { ModalYoutube } from './components/modal-youtube';
    import { ModalFormConvertFlow } from './components/modal-form-convert-flow';
    import { ModalSignUpForm } from './components/modal-sign-up-form';
    import { ModalSignInForm } from './components/modal-sign-in-form';
    import { ModalWebinars } from './components/modal-webinars';
    import { ModalRegisterForFreeClassForm } from './components/modal-register-for-free-class-form';

    export default defineComponent({
        name: 'ModalItem',
        components: {
            ModalWindow,
            ModalYoutube,
            ModalFormConvertFlow,
            ModalSignUpForm,
            ModalSignInForm,
            ModalWebinars,
            ModalRegisterForFreeClassForm
        },
        emits: ['close'],
        props: ['modal'],
        setup(props, { emit }) {
            const store = useStore();
            const root = ref(null);
            const state = reactive({
                isShowed: true
            });
            
            const modalContentItem = computed(() => {
                return `Modal${props.modal.content}`;
            });

            const onClose = (event) => {
                if (state.isShowed) {
                    emit('close', {
                        modal: props.modal
                    });

                    state.isShowed = false;
                }
            };

            return {
                state,
                root,
                modalContentItem,
                onClose
            }
        },
    })
