import { defineComponent, computed } from 'vue';
import ModalWindow from '/blocks/common/components/modal-window/modal-window.vue';

export const ModalWebinars = defineComponent({
    name: 'ModalWebinars',
    components: {
        ModalWindow
    },
    emits: ['close'],
    props: ['modal'],
    setup(props, { emit }) {
        const modalWindowOpen = true;
        function onCloseHandler(){
            emit('close', {
                modal: props.modal
            });
        };

        return () => (
            <ModalWindow open={modalWindowOpen} onClose={onCloseHandler}>
                <div class="modal-webinars">
                    <div class="modal-webinars__layout">
                        <button class="modal-webinars__button modal-webinars__button--close" type="button" v-on:click="onClose">
                            <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                        </button>

                            <p class="modal-webinars__heading">Upcoming App Demos</p>
                            <ul class="modal-webinars__webinars-list">
                                <li class="modal-webinars__webinars-item">
                                    <span class="modal-webinars__webinars-datetime">July 27, 9 AM EDT</span>
                                    <span class="modal-webinars__webinars-description">Roof Inspection</span>
                                    <a class="modal-webinars__webinars-link" href="https://attendee.gotowebinar.com/register/597257331552337165" rel="noopener" target="_blank">
                                        <span class="modal-webinars__webinars-link-caption">Register</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M17 7.5L7 17.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8 7.5H17V16.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </li>
                                <li class="modal-webinars__webinars-item">
                                    <span class="modal-webinars__webinars-datetime">July 27, 10 AM EDT</span>
                                    <span class="modal-webinars__webinars-description">Inspect & Maintain Vehicles</span>
                                    <a class="modal-webinars__webinars-link" href="https://register.gotowebinar.com/register/1815905442244401677" rel="noopener" target="_blank">
                                        <span class="modal-webinars__webinars-link-caption">Register</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M17 7.5L7 17.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8 7.5H17V16.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </li>
                                <li class="modal-webinars__webinars-item">
                                    <span class="modal-webinars__webinars-datetime">July 28, 10 AM EDT</span>
                                    <span class="modal-webinars__webinars-description">Job Estimates and Invoices</span>
                                    <a class="modal-webinars__webinars-link" href="https://register.gotowebinar.com/register/4505699011389122061" rel="noopener" target="_blank">
                                        <span class="modal-webinars__webinars-link-caption">Register</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M17 7.5L7 17.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8 7.5H17V16.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </li>
                                <li class="modal-webinars__webinars-item">
                                    <span class="modal-webinars__webinars-datetime">July 29, 10 AM EDT</span>
                                    <span class="modal-webinars__webinars-description">Construction Daily Log</span>
                                    <a class="modal-webinars__webinars-link" href="https://register.gotowebinar.com/register/1876174072609400845" rel="noopener" target="_blank">
                                        <span class="modal-webinars__webinars-link-caption">Register</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M17 7.5L7 17.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8 7.5H17V16.5" stroke="#007EDB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                    </div>
                </div>
            </ModalWindow>
        )
    }
});

export type ModalWebinars = InstanceType<typeof ModalWebinars>