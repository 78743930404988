import Swiper, { Navigation, Pagination } from 'swiper';

export class MobileAppDevelopment {
    store = null;

    async init({ store }) {
        this.store = store;
        await this._initCarousel();
    }

    _initCarousel() {
        const swiper = new Swiper('.swiper', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            modules: [Navigation, Pagination],
        });
    }
}