



import { defineComponent, reactive, computed, ref, toRefs, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { addStat, setCookie, getCookie, checkEmailOnServer, getRequestParam, guid } from '/blocks/common/components/utility';

export const StepErrorRecognizing = defineComponent({
    name: 'StepErrorRecognizing',
    emits: ['cancel', 'nextStep'],
    components: {

    },
    props: {
        fileName: String,
        fileUrl: String
    },
    setup(props, { emit }) {
        const store = useStore();
        const refForm = ref(null);
        const cancel = () => emit('cancel');
        const back = () => emit('nextStep', { stepName: 'howYouWantConvert' });

        const doManually = () => {
            console.log('doManually');
            setCookie('editorExpertMode', '0');
            setCookie('headerVisible', '1');

            const url = 'handlers/appbuilder.ashx';
            const formData = new FormData();
            const userId = getCookie('userid');
            const userguid = getCookie('userguid');

            formData.append('hideEmptyFormCategories', true.toString());
            formData.append('mode', 'form');
            formData.append('type', 'createNewApp');
            formData.append('userId', userId);
            formData.append('guid', userguid);
            formData.append('sessionkey', guid());
            formData.append('defaultCanvas', 'standart');

            return axios
                .post(url, formData)
                .then((response) => {
                    try {
                        let msg = response.data;

                        if (msg && msg.appId && parseInt(msg.appId) > 0) {
                            setCookie('openFormLibrary', '0');

                            const location = `wizard.aspx?cmd=loadApp&appId=${msg.appId}&guid=${getCookie("userguid")}&showUploadFormDialog=yes&fl=no`;
                            window.location.href = location;
                            return;
                        }
                        else {
                            throw "Server does not return new appId";
                        }
                    }
                    catch (e) {
                    }

                });
        }     

        return () => (
            <div class="step-error-recognizing">
                <div class="step-error-recognizing__header">
                    <button class="step-error-recognizing__button step-auth__button--header-cancel" onClick={cancel}>
                        <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                    </button>
                </div>
                <div class="step-error-recognizing__body">
                    <img src="storage/images/home-page/v1/heroSection/asset-12.png" class="sn__margin-top-20"/>
                    <p class="step-error-recognizing__heading">Error while recognizing the form</p>
                    <p class="step-error-recognizing__description">Please try to create form manually in Form Converter.</p>
                    <div class="step-error-recognizing__form-converter-button-container">
                        <button
                            class="step-error-recognizing__button step-error-recognizing__button--go-form-converter"
                            onClick={doManually}
                        >
                            Go to Form Converter
                        </button>
                    </div>
                </div>
                        <div class="step-error-recognizing__footer">
                    <button class="step-error-recognizing__button step-error-recognizing__button--back" onClick={back}>
                                Back
                            </button>
                    <button class="step-error-recognizing__button step-error-recognizing__button--cancel" onClick={cancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
        )
    },
})

export type StepErrorRecognizing = InstanceType<typeof StepErrorRecognizing>