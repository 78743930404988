import { Dialogs} from './dialogs'

export class MainNav {
    mainNav: HTMLElement;
    pageBody: HTMLElement;

    async init(store) {
        this.mainNav = document.querySelector('.main-nav');
        this.pageBody = document.querySelector('.page__body');

        const loginButton = document.querySelector('.main-nav__primary-button--log-in');
        if (loginButton !== null) {
            loginButton.addEventListener('click', (event) => {
                store.dispatch('modalManagementSystem/addModalWindow', {
                    content: 'SignInForm',
                });    
            });
        }

        const signUpButton = document.querySelector('.main-nav__primary-button--sign-up');
        if (signUpButton !== null) {
            signUpButton.addEventListener('click', (event) => {
                store.dispatch('modalManagementSystem/addModalWindow', {
                    content: 'SignUpForm',
                });    
            });
        }

        const hamburgerButton = document.querySelector('.main-nav__hamburger-button');

        hamburgerButton.addEventListener('click', () => {
            this.mainNav.classList.toggle('main-nav--mobile-show')
            this.pageBody.classList.toggle('page__body--modal-show')
        })

        const closeTopMenuButton = document.querySelector('.main-nav__close-button');
        closeTopMenuButton.addEventListener('click', () => {
            this.mainNav.classList.toggle('main-nav--mobile-show')
            this.pageBody.classList.toggle('page__body--modal-show')
        })
    }
}