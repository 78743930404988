import { Dialogs } from '../dialogs';
import { Header } from '../header';
import { GoogleAuth } from '../auth/google-auth';
import { FaceBookAuth } from '../auth/facebook-auth';
import { ModalManagementSystem } from '../../applications/modal-system';
import store from '../store';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Webinar } from '../webinar';
import { DocumateScannerBanner } from '../documate-scanner-banner';
import { LocationHashHandler } from '../location-hash-handler';
import { LiveChat } from '../live-chat';


export class Application {
    public store = null;
    public dialogueSystem = null;

    async init() {
        this.store = store;

        const googleAuth = new GoogleAuth();
        googleAuth.init();

        const faceBookAuth = new FaceBookAuth();
        await faceBookAuth.init();

        const header = new Header();
        header.init({
            store: this.store
        });

        const webinar = new Webinar();
        webinar.init({
            store: this.store
        });

        const documateScannerBanner = new DocumateScannerBanner();
        documateScannerBanner.init({
            store: this.store
        });

        const dialogs = new Dialogs();
        dialogs.init();

        this.dialogueSystem = new ModalManagementSystem();
        this.dialogueSystem.init({
            store: this.store
        });

        const locationHashHandler = new LocationHashHandler();
        locationHashHandler.init({
            store: this.store
        });

        const liveChat = new LiveChat();
        liveChat.init();
    }
}