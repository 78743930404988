export class DocumateScannerBanner {
    public store;

    async init({ store }) {
        this.store = store;
        this._websiteHeaderWebinarHandler();
    }

    _websiteHeaderWebinarHandler() {
        let wSettings = {
            isActive: true,
            isUserHideWebinarKey: 'userHideDocumateScannerBanner',
        }

        let wControls = {
            mainBox: document.querySelector('.documate-scanner-banner'),
            joinWorkshop: document.querySelector('.documate-scanner-banner__button--join-workshop'),
            closeWebinarAnnouncement: document.querySelector('.documate-scanner-banner__button--close-announcement'),
            showWebinarsButton: document.querySelector('.documate-scanner-banner__button-text--call-to-watch')
        }

        function hasEnabledWebinar() {
            return wSettings.isActive;
        }

        function isWebinarMimized() {
            const isUserMinimizedWebinar = document.cookie.match(new RegExp(
                "(?:^|; )" + wSettings.isUserHideWebinarKey.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));

            const isUserHideWebinarResult = isUserMinimizedWebinar ? decodeURIComponent(isUserMinimizedWebinar[1]) : undefined;

            return isUserHideWebinarResult === 'yes';
        }

        if (hasEnabledWebinar()) {
            wControls.mainBox.classList.add('documate-scanner-banner--active');

            if (isWebinarMimized()) {
                wControls.mainBox.classList.add('documate-scanner-banner--minimized');
            }
        }


        wControls.closeWebinarAnnouncement.addEventListener('click', (event) => {
            document.cookie = wSettings.isUserHideWebinarKey + '=yes;';
            wControls.mainBox.classList.add('documate-scanner-banner--minimized');
        });

        wControls.joinWorkshop.addEventListener('click', () => {
            document.cookie = wSettings.isUserHideWebinarKey + '=no;';
            wControls.mainBox.classList.remove('documate-scanner-banner--minimized');
        })
    }
} 