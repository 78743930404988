import { defineComponent, ref, computed, reactive, onMounted, watch, toRefs } from 'vue';
import axios, { AxiosRequestConfig } from 'axios';
import { getCookie, setCookie, guid } from '/blocks/common/components/utility';

export const StepChooseTemplate = defineComponent({
    name: 'StepChooseTemplate',
    emits: ['cancel', 'nextStep'],
    components: {
    },
    setup(props, { emit }) {
        const root = ref(null);
        const state = reactive({
            topCategories: [
                { id: 38, name: 'Inspection' },
                { id: 20, name: 'Construction' },
                { id: 67, name: 'General' },
            ],
            isSearchEmpty: false,
            categories: [],
            industries: [],
            path: '../../storage/widgetsData/',
            searchFormString: '',
            selectedCategory: null,
            selectedIndustry: null,
            pdfWidgets: null,
            isEmptyPDF: false,
            awaitingSearch: false,
            selectedWidget: computed(() => {
                if (state.selectedIndustry == null) {
                    return;
                }

                if (state.pdfWidgets === null || state.pdfWidgets.length == 0) {
                    return null;
                }

                return state.pdfWidgets[state.versionNum - 1];
            }),
            versionNum: 0,
            versionsCount: 0,
            formScreenshot: computed(() => {
                if (state.selectedWidget == null) {
                    return '';
                }

                return `${state.path}${state.selectedWidget.id}/screenshot.png`;
            }),
            pdfScreenshot: computed(() => {
                if (state.selectedWidget == null) {
                    return '';
                }

                return (state.selectedWidget.pdfTemplates) ? state.path + state.selectedWidget.pdfTemplates[0].id + '/screenshot.png' : '';

            }),
            hasNext: computed(() => { return state.versionsCount > 0 && state.versionNum < state.versionsCount }),

        });

        const toIntro = () => emit('nextStep', { stepName: 'startIntro' });
        const cancel = () => emit('cancel');
        const back = () => emit('nextStep', { stepName: 'howYouWantConvert' });
        const selectCategory = (category) => state.selectedCategory = category;

        const getCategories = async () => {
            const url = 'handlers/widgets.ashx?type=getNoviceTabsCategories';
            const formData = new FormData();
            formData.append('hideEmptyFormCategories', true.toString());

            try {
                const response = await axios.post(url, formData)
                return response.data.categories;
            } catch (e) {

            }
        }

        const getIndustries = async () => {
            const url = 'handlers/widgets.ashx?type=getNoviceTabsIndustries';
            const formData = new FormData();

            formData.append('categoryId', state.searchFormString !== '' ? undefined : state.selectedCategory && state.selectedCategory.id);
            formData.append('isPdfTemplates', false.toString());
            formData.append('isPdfFormLibrary', true.toString());
                formData.append('searchString', state.searchFormString);

                try {
                    const response = await axios.post(url, formData);
                    return response.data.tabs;
                } catch (e) {

                }
            }

            const getWidgets = async (change) => {
                state.pdfWidgets = state.selectedIndustry.screenshotFiles;

                change = change || 0;
                state.versionNum += change;

                if (state.versionNum > state.versionsCount) {
                    state.versionNum = 1;
                }

                if (state.versionNum < 1) {
                    state.versionNum = state.versionsCount;
                }
            }

            const selectIndustry = (industry) => {
                state.selectedIndustry = industry;
            }

            onMounted(async () => {
                state.categories = await getCategories();
                state.industries = await getIndustries();
            });

            watch(
                () => state.searchFormString,
                (searchFormString, prevSearchFormString) => {
                    if (!state.awaitingSearch) {
                        setTimeout(async () => {
                            state.industries = await getIndustries();
                            state.awaitingSearch = false;
                        }, 1300);
                    }
                    state.awaitingSearch = true;
                }
            )

            watch(
                () => state.selectedIndustry,
                (selectedIndustry, prevSelectedIndustry) => {
                    if (selectedIndustry !== null) {
                        state.versionNum = 1;
                        state.versionsCount = selectedIndustry.screenshotFiles.length;
                        getWidgets(undefined);
                    }
                }
            )

            watch(
                () => state.selectedCategory,
                async (selectedCategory, prevSelectedCategory) => {
                    if (selectedCategory !== null) {
                        state.industries = await getIndustries();
                    }
                }
            )

            const nextWidget = () => {
                if (state.versionNum < state.versionsCount) {
                    state.versionNum++;

                    return;
                }

                if (state.versionNum === state.versionsCount && state.versionsCount > 1) {
                    state.versionNum = 1;

                    return;
                }
            }

            const prevWidget = () => {
                if (state.versionNum > 1) {
                    state.versionNum--;

                    return;
                }

                if (state.versionNum === 1 && state.versionsCount > 1) {
                    state.versionNum = state.versionsCount;

                    return;
                }
            }

            const selectWidget = async () => {
                root.value.classList.add('choose-template--loading');
                setCookie('editorExpertMode', '0');
                setCookie('headerVisible', '1');


                const createAppFormData = new FormData();
                createAppFormData.append('mode', 'form');
                createAppFormData.append('type', 'createNewApp');
                createAppFormData.append('userId', getCookie('userid'));
                createAppFormData.append('guid', getCookie('userid'));
                createAppFormData.append('sessionkey', guid());
                createAppFormData.append('defaultCanvas', 'standart');

                const createAppRequestConfig: AxiosRequestConfig = {
                    method: 'post',
                    url: 'handlers/appbuilder.ashx',
                    data: createAppFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                };

                try {
                    const createAppResult = await axios(createAppRequestConfig);
                    const msg = createAppResult.data;

                    if (msg && msg.appId > 0) {
                        setCookie('openFormLibrary', '1');

                        window.location.href = 'wizard.aspx?cmd=loadApp&appId=' + msg.appId + '&guid=' + getCookie("userguid") + "&fl=yes" + "&widgetid=" + state.selectedWidget.id;
                        root.value.classList.remove('choose-template--loading');
                        return;
                    } else {
                        throw 'Server does not return new appId';
                    }
                } catch (e) {
                    root.value.classList.remove('choose-template--loading');
                }

            };

        return () => (
            <div ref={root} class="choose-template">
                <div class="choose-template__header">
                </div>
                <div class="choose-template__body">
                    <div class="choose-template__body-header">
                        <p class="choose-template__body-heading">Form and Reports Templates</p>
                        <div class="choose-template__search-form-wrapper">
                            <div class="choose-template__search-form-icon"></div>
                            <input 
                                class="choose-template__search-form-input" 
                                value={state.searchFormString}
                                placeholder="Search a form"
                             />
                        </div>
                    </div>
                <div class="choose-template__categories-container">
                    <div class="choose-template__categories">
                        <div class="choose-template__categories-top-heading">
                            Top categories
                        </div>
                        <ul class="choose-template__categories-top-items">
                                {
                                    state.topCategories
                                        .map((item) => {
                                            return <li
                                                id={item.id}
                                                key={item.id}
                                                class={`choose-template__categories-top-item${(state.selectedCategory !== null && item.id == state.selectedCategory.id) ? " choose-template-category-selected": ""}`}
                                                onClick={() => selectCategory(item)}
                                            >
                                                {item.name}
                                            </li>
                                })
                            }
                        </ul>
                        <div class="choose-template__categories-all-heading">
                            All categories
                        </div>
                        <ul class="choose-template__categories-all-items">
                            {
                                state.categories
                                    .map((item) => {
                                        return <li
                                            id={item.id}
                                            key={item.id}
                                            class={`choose-template__categories-all-item${(state.selectedCategory !== null && item.id == state.selectedCategory.id) ? " choose-template-category-selected" : ""}`}
                                            onClick={() => selectCategory(item)}
                                        >
                                            {item.name}
                                        </li>
                                    })
                            }
                        </ul>
                    </div>
                </div>

            <div class="choose-template__industries-container">
                <div class="choose-template__industries">
                    <div class="choose-template__industries-heading">
                    </div>
                    <ul class="choose-template__industries-items">
                        {
                           state.industries
                                .map((item) => {
                                    return <li
                                        id={item.id}
                                        key={item.id}
                                        class={`choose-template__industries-item${(state.selectedCategory !== null && item.id == state.selectedCategory.id) ? " choose-template-industry-selected" : ""}`}
                                        onClick={() => selectIndustry(item)}
                                    >
                                        {item.name}
                                    </li>
                                })
                        }
                    </ul>
                </div>
            </div>

            <div class="choose-template__widget">
                <p class="choose-template__widget-version-heading">Choose a version</p>
                        <p class="choose-template__widget-version">Version {state.versionNum} / {state.versionsCount } </p>
                <div class="choose-template-widget__image-container">
                    <div class="choose-template__screen-form-navigation-left">
                         <button class="choose-template__widget-prev-button" onClick={prevWidget}>
                            <img src="storage/images/home-page/v1/heroSection/arrow-left.svg" />
                        </button>
                    </div>

                    <div
                        class={`choose-template__widget-screen-report${(!state.isEmptyPDF && !(state.isSearchEmpty)) ? " custom-bg" : ""}${(state.isSearchEmpty) ? " blank-screen-report": ""}`}
                        style={`${state.pdfScreenshot ? `background-image: url('${state.pdfScreenshot}')` : ""}`}
                        >
                        <div class="choose-template__widget-screen-form">
                                    <div
                                        class={`choose-template__widget-form-view ${state.isSearchEmpty ? "blank-screen-form" : ''}`}
                                        style={`${state.pdfScreenshot ? `background-image: url('${state.formScreenshot}')`: ""}`}
                                    >
                                    </div>
                        </div>
                    </div>

                    <div class="choose-template__screen-form-navigation-right">
                        <button class="choose-template__widget-next-button" onClick={nextWidget}>
                            <img src="storage/images/home-page/v1/heroSection/arrow-right.svg" />
                        </button>
                    </div>
                </div>
            </div >
        </div >
                    <div class="choose-template__footer">
                        <button class="choose-template__button choose-template__button--back" onClick={back}>
                            Back
                        </button>
                        <button disabled={!state.selectedWidget} class="choose-template__button choose-template__button--next" onClick={selectWidget}>
                            Select
                        </button>
                        <button class="choose-template__button choose-template__button--cancel" onClick={cancel}>
                            Cancel
                        </button>
                    </div >
                    <div class="choose-template__loader-container">
                        <div class="loader">
                            <div class="loader__spinner"></div>
                        </div>
                    </div>
                </div>
            )
        },
    })

export type StepChooseTemplate = InstanceType<typeof StepChooseTemplate>