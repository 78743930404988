import axios, { AxiosRequestConfig } from 'axios';

export function checkForExcludedFromRedirectPages() {
    const url = window.location.pathname.substring(1).split('/')[0];
    const excludedPages = ['pricing', 'pricing.aspx', 'app', 'mobile-forms.aspx', 'mobile-forms'];

    if (excludedPages.includes(url)) {
        return true;
    } else {
        return false
    }

}
export function checkEmail(email) {
    const emailPattern = /^([\w]+)(.[\w]+)*@([\w-]+\.)+([A-Za-z])+$/;

    if (email === null) {
        return false;
    }

    if (email === '') {
        return false;
    }

    return emailPattern.test(email.trim());
}

export function setCookie(c_name, value, expiredays?, path?: string) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    var cookieStr = c_name + "=" + escape(value) + (expiredays == null ? "" : ";expires=" + exdate.toUTCString()) + (path == null ? "" : ";path=" + path)

    document.cookie = cookieStr;
}

export function getUrlPath(url) {
    var a = document.createElement('a');
    a.href = url;
    return a['protocol'] + '//' + a['host'] + a['pathname'];
}

export function getCookie(c_name) {
    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");

        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            let c_end = document.cookie.indexOf(";", c_start);

            if (c_end == -1) {
                c_end = document.cookie.length;
            }

            return unescape(document.cookie.substring(c_start, c_end));
        }
    }

    return ``;
}

export  function getQueryParam(param) {
    var linkTo = '';
    var x = location.search.substr(1).split("&")
    for (var i = 0; i < x.length; i++) {
        var y = x[i].split("=");
        if (y[0] == param) {
            linkTo = y[1];
        }
    }
    return linkTo;
}

export function getHashParam(param) {
    var linkTo = '';
    var x = location.hash.substr(1).split("&")
    for (var i = 0; i < x.length; i++) {
        var y = x[i].split("=");
        if (y[0] == param) {
            linkTo = y[1];
        }
    }
    return linkTo;
}

export function getRequestParam(param) {
    var res = '';
    res = getHashParam(param);
    if (res == '')
        res = getQueryParam(param);
    return res;
}

export function addStat(action) {
    let hUrl = "handlers/stat.ashx?cmd=addStat&action=" + encodeURIComponent(action) + (document.referrer ? ("&referrer=" + encodeURIComponent(document.referrer)) : "");
    return axios.get(hUrl);
}

export function userIsloggedIn(): boolean {
    const bodyElement: HTMLElement = document.querySelector('.page__body');
    if (bodyElement === undefined) {
        return false;
    }

    const userGuid: string = bodyElement.dataset['userguid'];
    if (userGuid === undefined) {
        return false;
    }

    if (userGuid === '-1') {
        return false;
    }

    return true;
}

export async function createAndRedirectToNewApp({ store }) {
    let userIsloggedInResult: boolean = userIsloggedIn();

    if (userIsloggedInResult) {
        const createNewAppResult = await createNewApp();

        if (!createNewAppResult.hasOwnProperty('appId')) {
            return false;
        }

        const buildAppUrlResult = await buildAppUrl(createNewAppResult.appId, getCookie("userguid"));
        window.location.href = buildAppUrlResult;
    } else {
        store.dispatch('modalManagementSystem/addModalWindow', {
            content: 'SignUpForm',
        });
        setCookie('redirectAction', 'createNewApp');
    }
}

export function buildAppUrl(appId: string, userGuid: string) {
    return `wizard.aspx?cmd=loadApp&appId=${appId}&guid=${userGuid}&fl=yes`;
}

export async function createNewApp() {
    const createNewAppOptData = new FormData();
    createNewAppOptData.append('type', 'createNewApp');
    createNewAppOptData.append('userId', getCookie('userid'));
    createNewAppOptData.append('guid', getCookie("userguid"));
    createNewAppOptData.append('sessionkey', guid());
    createNewAppOptData.append('defaultCanvas', 'standart');

    const createNewAppOpt: AxiosRequestConfig = {
        method: 'post',
        url: 'handlers/appbuilder.ashx',
        data: createNewAppOptData,
        headers: { 'Content-Type': 'multipart/form-data' },
    };

    return await axios(createNewAppOpt)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        })
}

export async function checkEmailOnServer(email) {
    const checkEmailOnServerFormData = new FormData();
    checkEmailOnServerFormData.append('type', 'checkEmail');
    checkEmailOnServerFormData.append('email', email);

    const checkEmailOnServerOpt: AxiosRequestConfig = {
        method: 'post',
        url: 'handlers/registration.ashx',
        data: checkEmailOnServerFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
    };

    return await axios(checkEmailOnServerOpt)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        })
}

function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function guid() {
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export function getUserInfo() {
    const url = "handlers/appbuilder.ashx?type=getUserInfo&guid=" + getCookie('userguid');
    let opt = {};

    return fetch(url, opt)
        .then(function (res) {
            return res.json();
        })
        .catch(function (error) {
            throw error;
        });
}

