import { Application } from '../../blocks/common/components/application';
import { MobileAppDevelopment } from '../../blocks/pages/home/mobile-app-development'

class Main extends Application {
    async init() {
        await super.init();

        const mobileAppDevelopment = new MobileAppDevelopment();
        const initOptions = {
            store: this.store
        };

        mobileAppDevelopment.init(initOptions);
    }
}

const application = new Main();
application.init();
window['application'] = application;