import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { setCookie, getCookie, guid } from '/blocks/common/components/utility';

export const StepFormUploaded = defineComponent({
    name: 'StepFormUploaded',
    emits: ['cancel', 'nextStep'],
    components: {

    },
    props: {
        fileName: String,
        fileUrl: String
    },
    setup(props, { emit }) {
        const store = useStore();
        const refForm = ref(null);
        const root = ref(null);

        const cancel = () => emit('cancel');
        const back = () => emit('nextStep', { stepName: 'howYouWantConvert' });

        const doManually = () => {
            root.value.classList.add('step-form-uploaded--loading');

            setCookie('editorExpertMode', '0');
            setCookie('headerVisible', '1');

            const url = 'handlers/appbuilder.ashx';
            const formData = new FormData();
            const userId = getCookie('userid');
            const userguid = getCookie('userguid');

            formData.append('hideEmptyFormCategories', true.toString());
            formData.append('mode', 'form');
            formData.append('type', 'createNewApp');
            formData.append('userId', userId);
            formData.append('guid', userguid);
            formData.append('sessionkey', guid());
            formData.append('defaultCanvas', 'standart');


            return axios
                .post(url, formData)
                .then((response) => {
                    try {
                        root.value.classList.remove('step-form-uploaded--loading');

                        let msg = response.data;

                        if (msg && msg.appId && parseInt(msg.appId) > 0) {
                            setCookie('openFormLibrary', '0');

                            const location = `wizard.aspx?cmd=loadApp&appId=${msg.appId}&guid=${getCookie("userguid")}&showUploadFormDialog=yes&fl=no`;
                            window.location.href = location;
                            return;
                        }
                        else {
                            throw "Server does not return new appId";
                        }
                    }
                    catch (e) {
                        root.value.classList.remove('step-form-uploaded--loading');
                    }

                });
        }

        const doAutomatically = async () => {
            root.value.classList.add('step-form-uploaded--loading');
            const formData = new FormData();
            formData.append('isFullConfigNeeded', true.toString());
            formData.append('timehash', props.fileName.toString());

            let userId = getCookie("userid");
            let userGuid = getCookie("userguid");

            if (userGuid && userId) {
                formData.append('userId', userId);
                formData.append('userGuid', userGuid);
            }

            formData.append('type', 'formRecognition');
            formData.append('fileUrl', props.fileUrl.toString());
            formData.append('fileName', props.fileName.toString());
            formData.append('configMode', 'mobile');

            const automaticallyConfig: AxiosRequestConfig = {
                method: 'post',
                url: 'handlers/appbuilder.ashx',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            try {
                const createAppResult = await axios(automaticallyConfig);
                root.value.classList.remove('step-form-uploaded--loading');

                const msg = createAppResult.data;

                if (msg && msg.applicationId > 0) {
                window.location.href = "wizard.aspx?cmd=loadApp&appId=" + msg.applicationId + "&guid=" + getCookie("userguid") + "&fl=yes";

                    return;
                } else {
                    throw 'Server does not return new appId';
                }
            } catch (e) {
                root.value.classList.remove('step-form-uploaded--loading');
                emit('nextStep', { stepName: 'errorRecognizing' });
            }

        };

        return () => (
            <div ref={root} class="step-form-uploaded">
                <div class="step-form-uploaded__header">
                    <button class="step-form-uploaded__button step-auth__button--header-cancel" onClick={cancel}>
                        <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                    </button>
                </div>
                    <div class="step-form-uploaded__body">
                    <p class="step-form-uploaded__body-heading">Your form has been uploaded</p>
                    <p class="step-form-uploaded__file-name">{props.fileName }</p>
                        <p class="step-form-uploaded__description">How do you want to create a Mobile Form?</p>
                        <div class="step-how-convert__conversion-types-list">
                            <div class="step-how-convert__conversion-types-wrapper">
                                <div class="step-how-convert__conversion-types-item">
                                    <img class="step-how-convert__conversion-types-image"
                                        width="180"
                                        height="180"
                                        src="/storage/images/home-page/v1/heroSection/1-11(1).png"
                                    />
                                    <p class="step-how-convert__conversion-types-heading">Automatically (beta)</p>
                                    <p class="step-how-convert__conversion-types-description">
                                        The Form will be created<br />
                                        automatically
                                    </p>
                                <button class="step-how-convert__conversion-types-button" type="button" onClick={doAutomatically}>Select</button>
                                </div>
                            </div>
                            <div class="step-how-convert__conversion-types-wrapper step-how-convert__conversion-types-wrapper--separator">
                                <div class="step-how-convert__conversion-types-separator"></div>
                            </div>
                            <div class="step-how-convert__conversion-types-wrapper">
                                <div class="step-how-convert__conversion-types-item">
                                    <img class="step-how-convert__conversion-types-image" width="180" height="180" src="/storage/images/home-page/v1/heroSection/1-11(2).png"/>
                                    <p class="step-how-convert__conversion-types-heading">Manually</p>
                                    <p class="step-how-convert__conversion-types-description">
                                        Do it yourself in<br />
                                        Form Converter
                                    </p>
                                <button class="step-how-convert__conversion-types-button" type="button" onClick={doManually}>Select</button>
                                </div>
                            </div>
                        </div>
                            </div>
                            <div class="step-form-uploaded__footer">
                                <button class="step-form-uploaded__button step-form-uploaded__button--back" onClick={back}>
                                    Back
                                </button>
                                <button class="step-form-uploaded__button step-form-uploaded__button--cancel" onClick={cancel}>
                                    Cancel
                                </button>
                            </div>

                            <div class="step-form-uploaded__loader-container">
                                <div class="loader">
                                    <div class="loader__spinner"></div>
                                </div>
                            </div>
                        </div>
        )
    },
})

export type StepFormUploaded = InstanceType<typeof StepFormUploaded>