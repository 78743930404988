import { defineComponent, reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { RecaptchaVersion2 } from '/blocks/common/components/recaptcha-version-2';

export const StepAuth = defineComponent({
    name: 'StepAuth',
    components: {
        RecaptchaVersion2
    },
    emits: ['cancel', 'close', 'nextStep','skip'],
    setup(props, { emit }) {
        const form = ref(null);
        const store = useStore();
        const recaptchaLoaded = computed<boolean>(() => store.state.recaptchaLoaded);
        const siteKey = computed<string>(() => store.state.reCaptchaSiteKey);

        const state: any = reactive({
            description: 'Enter email to continue',
            error: '',
            buttonCaption: 'Next',
            captchaResponse: null,
            userEmail: null,
            userChecked: false,
            userExist: false,
            userName: null,
            userPassword: null
        });

        function cancelHandler(event) {
            emit('cancel');
        };

        function nextHandler(){
            emit('nextStep');
        };

        function onVerifyHandler(response){
            state.captchaResponse = response;
        };

        const simpleAuth = async () => {
            const registerFormData = new FormData();
            registerFormData.append('type', 'simpleAuth');
            registerFormData.append('login', state.userEmail);
            registerFormData.append('email', state.userEmail);
            registerFormData.append('password', state.userPassword);
            registerFormData.append('pass', state.userPassword);
            registerFormData.append('firstname', state.userName);
            registerFormData.append('lastname', '.');
            registerFormData.append('company', 'OFR-');
            registerFormData.append('jobtitle', 'OFR-');
            registerFormData.append('leadSource', 'OFR-');
            registerFormData.append('leadsourcedetails', 'OFR-');
            registerFormData.append('g-recaptcha-response', state.captchaResponse.response);

            const registerOpt: AxiosRequestConfig = {
                method: 'post',
                url: 'handlers/registration.ashx',
                data: registerFormData,
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            try {
                const simpleAuthResult = await axios(registerOpt);
                return simpleAuthResult;
            } catch (e) {

            }
        };

        const checkExistUser = () => {
            const url = 'handlers/registration.ashx';
            const formData = new FormData(form.value);
            formData.append('type', 'checkExistUserByEmail');

            return axios
                .post(url, formData)
                .then((response) => {
                    state.userChecked = true;
                    state.userExist = true;
                    state.buttonCaption = 'Login';
                    state.description = 'Enter password to continue';
                })
                .catch((error) => {
                    state.userChecked = true;
                    state.userExist = false;
                    state.buttonCaption = 'Create account';
                    state.description = 'Enter name and password to continue';
                });
        };


        const onSubmitFormHandler = async (event) => {
            event.preventDefault();

            if (state.userChecked) {
                const simpleAuthResult = await simpleAuth();
                if (simpleAuthResult.data === true) {
                    state.error = '';
                    emit('nextStep', { prev: true });
                }
            }

            return checkExistUser();
        }

        function toIntroHandler() {
            emit('nextStep', { stepName: 'startIntro' })
        };

        return () => (
            <div class="step-auth">
                <div class="step-auth__header">
                    <button class="step-how-you-want-convert__button step-how-you-want-convert__button--go-intro" onClick={toIntroHandler}>
                        <img src="storage/images/home-page/v1/heroSection/asset-40.png"/>
                    </button>
                    <button class="step-auth__button step-auth__button--header-cancel" onClick={cancelHandler}>
                        <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                    </button>
                </div>
                <div class="step-auth__body">
                    <p class="step-auth__heading">{state.description}</p>
                    <div class="step-auth__form-container">
                        <form ref="form" class="step-auth__form" onSubmit={onSubmitFormHandler}>
                            <div class="step-auth__form-fields-container">
                                <input class="step-auth__form-input"
                                    name="email" 
                                    onInput={(event: any) => state.userEmail = event.target.value}
                                    placeholder="email"
                                    required
                                    type="email"
                                    value={state.userEmail}
                                />
                                {
                                    (state.userChecked && !state.userExist) &&
                                    <input
                                        class="step-auth__form-input"
                                        onInput={(event: any) => state.userName = event.target.value}
                                        placeholder="name"
                                        required
                                        type="text"
                                        value={state.userName}
                                    />
                                }
                                {
                                    (state.userChecked) &&
                                    <input
                                        class="step-auth__form-input"
                                        onInput={(event: any) => state.userPassword = event.target.value}
                                        placeholder="password"
                                        required
                                        name="password"
                                        type="password"
                                        value={state.userPassword}
                                    />
                                }

                                <div class="step-auth__form-error">{state.error}</div>
                                <RecaptchaVersion2
                                    apiLoaded={recaptchaLoaded.value}
                                    siteKey={siteKey.value}
                                    onVerify={onVerifyHandler}>
                                </RecaptchaVersion2>
                            </div>
                                <button
                                    class="step-auth__button step-auth__button--next"
                                    onClick={nextHandler}
                                    type="submit"
                                >
                                    { state.buttonCaption }
                                </button>
                        </form>    
                    </div>
                </div>
                <div class="step-auth__footer">
                    <button class="step-auth__button step-auth__button--back">
                        Back
                    </button>
                    <button class="step-auth__button step-auth__button--cancel" onClick={cancelHandler}>
                        Cancel
                    </button>
                </div>
            </div>
        )
    }
});

export type StepAuth = InstanceType<typeof StepAuth>