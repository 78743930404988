import { addStat, setCookie, getCookie, getUrlPath } from '../utility';
import { Dialogs } from '../dialogs';
import axios, { AxiosRequestConfig } from 'axios';

export class OAuth {
    static connectOuathToIphoneUser(token, provider) {
        const connectOuathToIphoneUserOptFormData = new FormData();
        connectOuathToIphoneUserOptFormData.append('type', 'connectOuathToIphoneUser');
        connectOuathToIphoneUserOptFormData.append('token', token);
        connectOuathToIphoneUserOptFormData.append('provider', provider);

        const connectOuathToIphoneUserOpt: AxiosRequestConfig = {
            method: 'post',
            url: 'handlers/registration.ashx',
            data: connectOuathToIphoneUserOptFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
        };

        return axios(connectOuathToIphoneUserOpt)
            .then((response) => {
                return;
            })
            .catch((response) => {

            })
    }

    static oAuthSingup(AAccessToken, provider) {
        if (getCookie('userguid') != "") {
            OAuth.connectOuathToIphoneUser(AAccessToken, provider)
        } else {
            const openAuthSinguFormData = new FormData();
            openAuthSinguFormData.append('type', 'openAuthSingup');
            openAuthSinguFormData.append('token', AAccessToken);
            openAuthSinguFormData.append('provider', provider);

            const openAuthSingupOpt: AxiosRequestConfig = {
                method: 'post',
                url: 'handlers/registration.ashx',
                data: openAuthSinguFormData,
                headers: { 'Content-Type': 'multipart/form-data' },
            };


            return axios(openAuthSingupOpt)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        if (response.data.message == 'Registration successfully.' || response.data.message == 'Login successfully.') {
                            let url = getCookie('redirect') || '/my-apps';
                            setCookie('redirect', '');
                            window.location.href = url;
                        }
                    } else {
                        if (response.data.message == 'Email already used.' && response.data.email != undefined) {
                            Dialogs._openLoginRegTabKp2('login_tab_btn_kp2');
                            const alertElement = document.querySelector('#login_tab_btn_kp2_content .alert-info');
                            alertElement.parentNode.removeChild(alertElement);


                            let notify = document.createElement('div');
                            notify.classList.add('alert', 'alert-info', 'fade', 'in');
                            notify.innerHTML = `User with email <strong>${response.data.email}</strong> already registered, Please login to connect your account`;

                            let parent = document.querySelector('#login_tab_btn_kp2_content');
                            parent.insertBefore(notify, parent.firstChild);                        
                            let emailInput: HTMLInputElement = document.querySelector('#login-email-txt-kp2');
                            emailInput.value = response.data.email;
                            setCookie('redirect', 'account#oAuthTab');
                        } else {
                            //apMessageBox.error({
                            //    title: "Error",
                            //    message: msg.message,
                            //});
                        }
                    }
                })
                .catch((response) => {

                })
        }
        Dialogs._closeLoginRegTabKp2();
    }

    static onSignIn(googleUser) {
        if (googleUser) {
            //TODO Fix GOOGLE OpenAUTH Event handler that sometimes generate event
            var xUrl = getUrlPath(window.location.href);
            var allouConnectAccount = xUrl.match(/account$/);
            if (getCookie('userguid') != "" && !allouConnectAccount) {
                //sendInfoToSnappii('', 'anarozhnyi@snappii.com', 'For some reason already loggined user try login again');
            } else {
                var xAuthResponse = googleUser.getAuthResponse();
                var xShowConsoleInfo = true; //xUrl.match(/localhost/gi);

                if (xShowConsoleInfo) {
                    console.group("Google Auth");
                    console.info("access_token: %s", xAuthResponse.access_token);
                    console.info("token_type: %s", xAuthResponse.token_type);
                    console.info("expires_in: %s", xAuthResponse.expires_in);
                    console.info("refresh_token: %s", xAuthResponse.refresh_token);
                    console.info("id_token: %s", xAuthResponse.id_token);
                    console.info("scope: %s", xAuthResponse.scope);
                    console.groupEnd();
                }

                //oAuthSingup(googleUser.getAuthResponse().id_token, 'Google');
                OAuth.oAuthSingup(xAuthResponse.access_token, 'Google');
            }
        } else {
            console.log(googleUser);
        }
    };
}