import { defineComponent} from 'vue';

export const StepHowConvert =  defineComponent({
        name: 'StepHowConvert',
        emits: ['cancel', 'back', 'nextStep'],
        components: {
        },
        setup(context, { emit }) {
            function cancelHandler() {
                emit('cancel');
            };

            function toIntroHandler() {
                emit('nextStep', { stepName: 'startIntro' });
            };

            function toHowYouWantConvertHandler() {
                emit('nextStep', { stepName: 'howYouWantConvert' });
            };

            function backHandler(event) {
                emit('back');
            };

            function doItForMeHandler(event) {
                window.location.href = '/do-it-for-me';
            };

           return () => (
                <div class="step-how-convert">
                    <div class="step-how-convert__header">
                       <button class="step-how-convert__button step-how-convert__button--go-intro" onClick={toIntroHandler}>
                            <img src="storage/images/home-page/v1/heroSection/asset-40.png"/>
                        </button>
                       <button class="step-how-convert__button step-how-convert__button--header-cancel" onClick={cancelHandler}>
                            <img src="storage/images/home-page/v1/heroSection/cross-cancel.svg"/>
                        </button>
                    </div>
                    <div class="step-how-convert__body">
                        <p class="step-how-convert__heading">
                            How do you want to convert your form?
                        </p>
                        <div class="step-how-convert__conversion-types-list">
                            <div class="step-how-convert__conversion-types-wrapper">
                               <div class="step-how-convert__conversion-types-item" onClick={toHowYouWantConvertHandler}>
                                   <img class="step-how-convert__conversion-types-image"
                                       width="180"
                                       height="180"
                                       src="storage/images/home-page/v1/heroSection/1-5(1).png"
                                   />
                                    <p class="step-how-convert__conversion-types-heading">Do it yourself</p>
                                    <p class="step-how-convert__conversion-types-description">
                                        Conversion is FREE.<br />
                                        Our usage pricing starts<br />
                                        at only $1!
                                    </p>
                                    <button class="step-how-convert__conversion-types-button" type="button">Select</button>
                                </div>
                            </div>

                            <div class="step-how-convert__conversion-types-wrapper step-how-convert__conversion-types-wrapper--separator">
                                <div class="step-how-convert__conversion-types-separator"></div>
                            </div>

                            <div class="step-how-convert__conversion-types-wrapper">
                               <div class="step-how-convert__conversion-types-item" onClick={doItForMeHandler}>
                                   <img
                                       class="step-how-convert__conversion-types-image"
                                       width="180"
                                       height="180"
                                       src="/storage/images/home-page/v1/heroSection/1-5(2).png"
                                   />
                                    <p class="step-how-convert__conversion-types-heading">Do it for me</p>
                                    <p class="step-how-convert__conversion-types-description">
                                        Tell us what you need,<br />
                                        send us your forms and<br />
                                        we will send you a free quote
                                    </p>
                                    <button class="step-how-convert__conversion-types-button" type="button">Select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-how-convert__footer">
                       <button class="step-how-convert__button step-how-convert__button--back" onClick={backHandler}>
                            Back
                        </button>
                       <button class="step-how-convert__button step-how-convert__button--cancel" onClick={cancelHandler}>
                            Cancel
                        </button>
                    </div>
                </div>
            )
        },
    });

export type StepHowConvert = InstanceType<typeof StepHowConvert>