import { OAuth } from './oauth'

export class FaceBookAuth {
    init() {
        (<any>window).fbAsyncInit = function () {
            FB.init({
                appId: '1128322717333754',
                cookie: true,
                xfbml: true,
                version: 'v3.2'
            });
        };
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }

            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    static facebookLogin() {
        if (typeof (FB) != 'undefined' && FB != null) {
            FB.login(function (response) {
                if (response.status === 'connected') {
                    var xAuthResponse = response.authResponse;
                    OAuth.oAuthSingup(xAuthResponse.accessToken, 'Facebook');

                    return;
                }

                if (response.status === 'not_authorized') {
                    return `Please log into this app.`;
                }

                return; `Please log into Facebook.`;

            }, { scope: 'public_profile,email' });
        } else {
            let content = `User try singup with facebook, but facebook library not loaded`;
        }
    }
}