import { defineComponent} from 'vue';

export const StepIntroduction = defineComponent({
    name: 'StepIntroduction',
    components: {
    },
    emits: ['cancel', 'close', 'nextStep','skip'],
    setup(props, { emit }) {
        function cancelHandler(event) {
            emit('cancel');
        };

        function nextHandler(event) {
            emit('nextStep');
        };

        function skipHandler(event) {
            emit('skip');
        }

        function dotSelectHandler(event) {
            if (event.srcElement.classList.contains('active')) {
                return;
            }

            if (!event.srcElement.classList.contains('step-introduction__dots-item')) {
                return;
            }

            const dotElementIndex = Array.from(event.currentTarget.children).indexOf(event.srcElement);
            const dotActiveElement = event.currentTarget.querySelector('.step-introduction__dots-item--active');
            const dotSelectedElementIndex = Array.from(event.currentTarget.children).indexOf(dotActiveElement);

            emit('nextStep', { stepIndex: dotElementIndex - dotSelectedElementIndex });
        }

        return () => (
            <div class="step-introduction">
                <div class="step-introduction__header">
                    <button class="step-introduction__button step-introduction__button--skip" onClick={skipHandler}>skip</button>
                    <div class="step-introduction__dots-items" onClick={dotSelectHandler}>
                        <div class="step-introduction__dots-item step-introduction__dots-item--active"></div>
                        <div class="step-introduction__dots-item"></div>
                    </div>
                    <button class="step-introduction__button step-introduction__button--header-cancel" onClick={cancelHandler}>
                        <img src="storage/images/home-page/v1/herosection/cross-cancel.svg" />
                    </button>
                </div>
                <div class="step-introduction__body">
                    <p class="step-introduction__description">
                        use your mobile device<br />
                        to fill out forms and<br />
                        file reports in a snap!
                    </p>
                    <picture class="step-introduction__image-container">
                        <img class="step-introduction__image" src="storage/images/home-page/v1/herosection/1-1.png" />
                    </picture>
                </div>
                <div class="step-introduction__footer">
                    <button class="step-introduction__button step-introduction__button--back">
                        back
                    </button>
                    <button class="step-introduction__button step-introduction__button--next" onClick={nextHandler}>
                        next
                    </button>
                    <button class="step-introduction__button step-introduction__button--cancel" onClick={cancelHandler}>
                        cancel
                    </button>
                </div>
            </div>
        )
    }
});

export type StepIntroduction = InstanceType<typeof StepIntroduction>