import { defineComponent, computed } from 'vue';
import ModalWindow from '/blocks/common/components/modal-window/modal-window.vue';
import { SignInForm } from '/blocks/common/components/sign-in-form';

export const ModalSignInForm = defineComponent({
    name: 'ModalSignInForm',
    components: {
        ModalWindow,
        SignInForm
    },
    emits: ['close'],
    props: ['modal'],
    setup(props, { emit }) {
        const modalWindowOpen = true;
        const onCloseHandler = () => {
            emit('close', {
                modal: props.modal
            });
        }

        return () => (
            <ModalWindow open={modalWindowOpen} onClose={onCloseHandler}>
                {{
                    default: () => <SignInForm onClose={onCloseHandler}></SignInForm>
                }}                  
            </ModalWindow>
        )
    }
});

export type ModalSignInForm = InstanceType<typeof ModalSignInForm>