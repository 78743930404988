import { defineComponent, reactive, computed } from 'vue';
import { useStore } from "vuex";
import { RecaptchaVersion2 } from '/blocks/common/components/recaptcha-version-2';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { checkEmail, addStat, setCookie, getCookie, checkEmailOnServer, getRequestParam, checkForExcludedFromRedirectPages } from '/blocks/common/components/utility';
import axios, { AxiosRequestConfig } from 'axios';
import { gapi } from 'gapi-script';
import { FaceBookAuth } from '/blocks/common/components/auth/facebook-auth';

export const SignInForm = defineComponent({
    name: 'SignInForm',
    components: {
        RecaptchaVersion2
    },
    emits: ['close'],
    setup(props, { attrs, slots, emit }) {
        const store = useStore();

        const goToSignUpForm = () => {
            emit('close');

            store.dispatch('modalManagementSystem/addModalWindow', {
                content: 'SignUpForm',
            });
        };
        const state = reactive({
            firstStepErrors: '',
            secondStepErrors: '',
            recaptchaResponse: null,
            userInfo: {
                email: null,
                password: null,
            }
        });

        const close = () => {
            emit('close');
        };

        const _validation = async () => {
            if (state.userInfo.password === null) {
                return false;
            }

            return true;
        }

        const forgotPassword = () => {
            if (checkEmail(state.userInfo.email) === false) {
                state.firstStepErrors = 'Email is incorrect.';
                return;
            }

            const form = new FormData();
            form.append('type', 'forgot');
            form.append('email', state.userInfo.email);

            const forgotOpt: AxiosRequestConfig = {
                method: 'post',
                url: 'handlers/registration.ashx',
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            };

            axios(forgotOpt)
                .then((response) => {
                    let alertText = '';

                    if (response.data === true) {
                        alertText = 'Instructions to reset your password have been emailed to you.';
                    } else if (response.data.trim() == 'notActivated') {
                        alertText = "Error! You need to verify your email address to login. <br /> Please check your email for the message from us and click the verification link.<br /> <br />  Click <a href='#' onclick='resendActivationMessage( $(\"#login-email-txt-kp2\").val())'>here</a> to get the verification email again.";
                    } else {
                        alertText = response.data;
                    }

                    state.firstStepErrors = alertText;
                })
                .catch((error) => {
                    console.log('err')
                })
        };

        const onSubmit = async (event) => {
            event.preventDefault();

            const validationResult = await _validation();
            if (validationResult === false) {
                return;
            }

            let openingTemplateId = null;
            let copingTemplateId = null;
            let showTrainingInfo = false;
            let selectedPurchase = null;

            const loginFormData = new FormData();
            loginFormData.append('type', 'login');
            loginFormData.append('pass', state.userInfo.password);
            loginFormData.append('login', state.userInfo.email);

            let sessionKey = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            loginFormData.append('sessionKey', sessionKey);

            const loginOpt: AxiosRequestConfig = {
                method: 'post',
                url: 'handlers/registration.ashx',
                data: loginFormData,
                headers: { 'Content-Type': 'multipart/form-data' },
            };


            return axios(loginOpt)
                .then((response) => {
                    if (response.data === true) {
                        addStat('LoginFromHomePage');

                        if (openingTemplateId != null) {
                            setCookie("openingTemplateId", openingTemplateId, 1);
                            window.location.reload();

                            return;
                        }

                        if (copingTemplateId != null) {
                            setCookie("copingTemplateId", copingTemplateId, 1);
                            window.location.reload();

                            return;
                        }

                        if (showTrainingInfo == true) {
                            window.location.href = '/?showTrainingInfo=1';

                            return;
                        }

                        setCookie('login', 1, 1);
                        if (getCookie('redirect') != '') {
                            window.location.href = getCookie('redirect');
                            setCookie('redirect', '');

                            return;
                        } else {
                            if (!checkForExcludedFromRedirectPages()) {
                                window.location.href = 'my-apps';

                                return;
                            } else {
                                location.reload();
                                return;
                            }
                        }
                    }

                    if (response.data === 'notActivated') {
                        const msg: string = ``;
                        state.firstStepErrors = `Error! You need to verify your email address to login. <br /> Please check your email for the message from us and click the verification link.<br /> <br />  Click <a href='#' onclick='resendActivationMessage( $(\"#login-email-txt-kp2\").val())'>here</a> to get the verification email again."`;

                        return;
                    }

                    if (response.data !== '') {
                        state.firstStepErrors = `Error: ${response.data}`;
                    }
                })
                .catch((error) => {

                })
        };

        const rules: any = {
            fullName: {
                required
            },
            email: {
                required,
                email
            }
        };

        const v$ = useVuelidate(rules, state.userInfo)
        const onVerifyRecaptcha = (event) => {
            state.recaptchaResponse = event.response;
        }

        const logInWithGoogle = () => {
            gapi.auth2.getAuthInstance().signIn();
        };

        const logInWithFacebook = () => {
            FaceBookAuth.facebookLogin();
        };

        return () => (
            <>
                <div class="sign-in-form">
                    <div class="sign-in-form__layout">
                        <div class="sign-in-form__form sign-in-form__form--step-1">
                            <button class="sign-in-form__button sign-in-form__button--step-1-close" onClick={close} type="button">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M18 2L2 18" stroke="#757575" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2 2L18 18" stroke="#757575" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                            <p class="sign-in-form__heading-2">Log in</p>
                            <p class="sign-in-form__text sign-in-form__text--have-an-account">Don’t have an account? <button class="sign-in-form__link" onClick={goToSignUpForm}>Sign Up</button></p>
                            <button class="sign-in-form__button sign-in-form__button--google" onClick={logInWithGoogle}>
                                <svg class="sign-in-form__button-icon" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <circle cx="18" cy="18" r="17" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.56 18.25C28.56 17.47 28.49 16.72 28.36 16H18V20.255H23.92C23.665 21.63 22.89 22.795 21.725 23.575V26.335H25.28C27.36 24.42 28.56 21.6 28.56 18.25Z" fill="#4285F4" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9999 28.9998C20.9699 28.9998 23.4599 28.0148 25.2799 26.3348L21.7249 23.5748C20.7399 24.2348 19.4799 24.6248 17.9999 24.6248C15.1349 24.6248 12.7099 22.6898 11.8449 20.0898H8.16992V22.9398C9.97992 26.5348 13.6999 28.9998 17.9999 28.9998Z" fill="#34A853" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.845 20.0896C11.625 19.4296 11.5 18.7246 11.5 17.9996C11.5 17.2746 11.625 16.5696 11.845 15.9096V13.0596H8.17C7.425 14.5446 7 16.2246 7 17.9996C7 19.7746 7.425 21.4546 8.17 22.9396L11.845 20.0896Z" fill="#FBBC05" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9999 11.375C19.6149 11.375 21.0649 11.93 22.2049 13.02L25.3599 9.865C23.4549 8.09 20.9649 7 17.9999 7C13.6999 7 9.97992 9.465 8.16992 13.06L11.8449 15.91C12.7099 13.31 15.1349 11.375 17.9999 11.375Z" fill="#EA4335" />
                                </svg>
                                <span class="sign-in-form__button-text">Log in with Google</span>
                            </button>
                            <button class="sign-in-form__button sign-in-form__button--facebook" onClick={logInWithFacebook}>
                                <svg class="sign-in-form__button-icon" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0001 1C27.3966 1 35.0003 8.6037 35.0003 18.0001C35.0003 26.4794 28.7953 33.5078 20.6583 34.797V22.9147H24.5993L24.5994 22.9138H24.6147L25.372 17.9992H20.6583V14.8155C20.6583 13.5319 21.264 12.2764 23.1477 12.1653C23.2323 12.1606 23.3195 12.1583 23.4093 12.1583H25.5575V12.1573H25.5729V7.96908C25.5729 7.96908 23.6256 7.64453 21.771 7.64453C21.5706 7.64453 21.3738 7.6508 21.1807 7.66329C17.628 7.88667 15.3419 10.2092 15.3419 14.2601V17.9992H11.03V22.9138H11.0301V22.9147H15.3419V34.7984C15.3419 34.7984 15.3419 34.7984 15.3419 34.7984V34.7994C7.21278 33.5167 1 26.4848 1 18.0001C1 8.6037 8.6037 1 18.0001 1Z" fill="white" />
                                </svg>
                                <span class="sign-in-form__button-text">Log in with Facebook</span>
                            </button>
                            <hr class="sign-in-form__horizontal-separator" />
                            <p class="sign-in-form__text sign-in-form__text--with-email">or log in with email:</p>
                            <form class="sign-in-form__first-step-form" onSubmit={onSubmit}>
                                <div class="sign-in-form__form-element">
                                    <label class="sign-in-form__label" for="username">Email *</label>
                                    <input class="sign-in-form__input" value={state.userInfo.email} onInput={(event: any) => state.userInfo.email = event.target.value} type="email" id="username" required />
                                </div>

                                <div class="sign-in-form__form-element sign-in-form__form-element--password">
                                    <label class="sign-in-form__label" for="countryCode">Password *</label>
                                    <button class="sign-in-form__button sign-in-form__button--forgot" type="button" onClick={forgotPassword}>Forgot password?</button>
                                    <input
                                        class="sign-in-form__input sign-in-form__input--password"
                                        value={state.userInfo.password}
                                        onInput={(event: any) => state.userInfo.password = event.target.value}
                                        type="password"
                                        name="password"
                                        required
                                    />
                                </div>

                                <div class="sign-in-form__first-step-errors" innerHTML={state.firstStepErrors}></div>
                                <button class="sign-in-form__button sign-in-form__button--create-account">
                                    <span class="sign-in-form__button-text">Log in</span>
                                </button>
                                <p class="sign-in-form__text">*required field</p>
                            </form>
                        </div>
                        <div class="sign-in-form__pros">
                            <button class="sign-in-form__button sign-in-form__button--close" onClick={close} type="button">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M18 2L2 18" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2 2L18 18" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        
                            <ul class="sign-in-form__benefits-list">
                                <li class="sign-in-form__benefits-item">
                                    <svg class="sign-in-form__benefits-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" fill="#69D631" />
                                        <path d="M22 4L12 14.01L9 11.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span class="sign-in-form__benefits-text">
                                        Free Forever Account
                                    </span>
                                </li>
                                <li class="sign-in-form__benefits-item">
                                    <svg class="sign-in-form__benefits-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" fill="#69D631" />
                                        <path d="M22 4L12 14.01L9 11.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span class="sign-in-form__benefits-text">
                                        No Credit Card Required
                                    </span>
                                </li>
                                <li class="sign-in-form__benefits-item">
                                    <svg class="sign-in-form__benefits-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" fill="#69D631" />
                                        <path d="M22 4L12 14.01L9 11.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span class="sign-in-form__benefits-text">
                                        Over 28,000 Apps built<br />
                                        and trusted by companies like:
                                    </span>
                                </li>
                            </ul>
                            <ul class="sign-in-form__clients-items">
                                <li class="sign-in-form__clients-item">
                                    <img class="sign-in-form__clients-logo" src="storage/images/blocks/common/components/sign-in-form/clients-ge.svg" alt=""/>
                                </li>
                                <li class="sign-in-form__clients-item">
                                    <img class="sign-in-form__clients-logo" src="storage/images/blocks/common/components/sign-in-form/clients-allianz.svg" alt=""/>
                                </li>
                                 <li class="sign-in-form__clients-item">
                                    <img class="sign-in-form__clients-logo" src="storage/images/blocks/common/components/sign-in-form/clients-driscolls.svg" alt=""/>
                                 </li>
                                 <li class="sign-in-form__clients-item">
                                    <img class="sign-in-form__clients-logo" src="storage/images/blocks/common/components/sign-in-form/clients-hyster.svg" alt=""/>
                                 </li>
                                 <li class="sign-in-form__clients-item">
                                    <img class="sign-in-form__clients-logo" src="storage/images/blocks/common/components/sign-in-form/clients-yale.svg" alt=""/>
                                 </li>
                                 <li class="sign-in-form__clients-item">
                                    <img class="sign-in-form__clients-logo" src="storage/images/blocks/common/components/sign-in-form/clients-nationalease.svg" alt=""/>
                                 </li>
                                 <li class="sign-in-form__clients-item">
                                    <img class="sign-in-form__clients-logo" src="storage/images/blocks/common/components/sign-in-form/clients-AFSC.svg" alt=""/>
                                 </li>
                            </ul>
                            <p class="sign-in-form__pros-text sign-in-form__pros-text--contact">
                                For group sales please contact<br />
                                <a class="sign-in-form__pros-link" href="mailto:sales@snappii.com">sales@snappii.com</a>
                            </p>
                            <picture class="sign-in-form__image-container">
                                <source srcset="" type="image/avif"/>
                                <source srcset="" type="image/webp"/>
                                <img class="sign-in-form__image" src="storage/images/blocks/common/components/sign-in-form/mobile-phones.png" alt=""/>
                            </picture>
                        </div>
                    </div>
                </div>
            </>
        )
    }
});

export type SignInForm = InstanceType<typeof SignInForm>
