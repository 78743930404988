import { defineComponent} from 'vue';
import ModalWindow from '/blocks/common/components/modal-window/modal-window.vue';
import { FormConvertFlow } from '/blocks/common/components/form-convert-flow';

export const ModalFormConvertFlow = defineComponent({
    name: 'ModalFormConvertFlow',
    components: {
        ModalWindow,
        FormConvertFlow
    },
    emits: ['close'],
    props: ['modal'],
    setup(props, { emit }) {
        const modalWindowOpen = true;
        const onCloseHandler = () => {
            emit('close', {
                modal: props.modal
            });
        }
           
        return () => (
            <ModalWindow open={modalWindowOpen} onClose={onCloseHandler}>
                {{
                    default: () => <FormConvertFlow onClose={onCloseHandler}></FormConvertFlow>
                }}
            </ModalWindow>
        )
    }
})

export type ModalFormConvertFlow = InstanceType<typeof ModalFormConvertFlow>