import { getCookie } from '/blocks/common/components/utility';
import { defineComponent, ref, reactive, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { StepIntroduction } from './components/step-introduction';
import { StepFormConverterAI } from './components/step-form-converter-ai';
import { StepHowConvert } from './components/step-how-convert'
import { StepHowYouWantConvert } from './components/step-how-you-want-convert'
import { StepAuth } from './components/step-auth'
import { StepChooseTemplate } from './components/step-choose-template'
import { StepUpload } from './components/step-upload'
import { StepFormUploaded } from './components/step-form-uploaded'
import { StepErrorRecognizing } from './components/step-error-recognizing'
   

export const FormConvertFlow = defineComponent({
        name: 'FormConvertFlow',
        components: {
            StepIntroduction,
            StepFormConverterAI,
            StepHowConvert,
            StepHowYouWantConvert,
            StepAuth,
            StepChooseTemplate,
            StepUpload,
            StepFormUploaded,
            StepErrorRecognizing
        },
        emits: ['close'],
        setup(props, { emit}) {
            const store = useStore();
            const root = ref(null);
            const state = reactive({
                step: null,
                prevStep: null,
                fileName: null,
                fileUrl: null
            });

            watch(
                () => state.step,
                (step: HTMLElement, prevStep: HTMLElement) => {
                    if (prevStep !== null) {
                        prevStep.classList.remove('form-convert-flow__item--active');
                    }

                    step.classList.add('form-convert-flow__item--active');
                }
            )

            function onCancelHandler() {
                emit('close');
            }

            const nextStepByName = (stepName: string): void => {
                state.step = root.value.querySelector(`.form-convert-flow__item[step-name="${stepName}"]`);
            }

            const isAuthNeeded = (step: HTMLElement): boolean => {
                const authNeeded = step.getAttribute('auth-needed');

                return authNeeded === 'true';
            }

            const isUserLoggedIn = () => {
                return getCookie('userguid') !== '';
            }

            const getNextStep = (opt: any): HTMLElement => {
                if (opt === undefined) {
                    const notHiddenSteps = root.value.querySelectorAll('.form-convert-flow__item:not([step-hidden="true"])');
                    const lastActiveElementIndex = Array
                        .from(notHiddenSteps)
                        .indexOf(state.step);

                    const nextStepIndex = lastActiveElementIndex + 1;
                    if (nextStepIndex > notHiddenSteps.length - 1) {
                        return;
                    }

                    return notHiddenSteps[nextStepIndex];
                }

                if (opt.prev === true) {
                    return state.prevStep;
                }

                if (opt.stepIndex !== undefined) {
                    const notHiddenSteps = root.value.querySelectorAll('.form-convert-flow__item:not([step-hidden="true"])');
                    let lastActiveElementIndex = Array.from(notHiddenSteps).indexOf(state.step);

                    return notHiddenSteps[lastActiveElementIndex + opt.stepIndex];;
                }

                if (opt.stepName !== undefined) {
                    return root.value.querySelector(`.form-convert-flow__item[step-name="${opt.stepName}"]`);;
                }
            }

            const getStepByName = (stepName: string) => {
                return root.value.querySelector(`.form-convert-flow__item[step-name="${stepName}"]`);
            }

            function onNextStepHandler(opt: any) {
                const newStep = getNextStep(opt) || state.prevStep;

                if (newStep === undefined) {
                    return;
                }

                const isAuthNeededResult = isAuthNeeded(newStep);
                const isUserLoggedInResult = isUserLoggedIn();

                if (isAuthNeededResult === true && isUserLoggedInResult === false) {
                    state.prevStep = newStep;
                    const stepAuth = getStepByName('auth');  
                    state.step = stepAuth;

                    return;
                }

                state.step = newStep;
            }

            function onBackHandler(opt: any){
                const notHiddenSteps = root.value.querySelectorAll('.form-convert-flow__item:not([step-hidden="true"])');
                let lastActiveElementIndex = Array.from(notHiddenSteps).indexOf(state.step);
                lastActiveElementIndex--;

                state.step = notHiddenSteps[lastActiveElementIndex];
            }

            const onUploaded = (event: any) => {
                state.fileUrl = event.fileUrl;
                state.fileName = event.fileName;
                onNextStepHandler({
                    stepName: 'formUploaded'
                })
            }

            function onSkipTutorialHandler(opt: any) {
                onNextStepHandler({
                    stepName: 'howConvert'
                })
            }

            const chooseTemplate = (opt: any) => {
                onNextStepHandler({
                    stepName: 'choose-template'
                })
            } 

            onMounted(() => {
                setTimeout(() => {
                    const cookieShowTutorialOfr = getCookie("showTutorialOfr");
                    const showTutorialOfr: boolean = cookieShowTutorialOfr === '' || cookieShowTutorialOfr === 'true';

                    if (showTutorialOfr) {
                        nextStepByName('startIntro');
                    } else {
                        nextStepByName('howConvert');
                    }
                }, 0)
            })

            return () => (
                <div ref={root} class="form-convert-flow">
                    <div ref="stepscontainer" class="form-convert-flow__items" >
                        <div class="form-convert-flow__item" step-name="startIntro">
                           <StepIntroduction onCancel={onCancelHandler} onNextStep={onNextStepHandler} onSkip={onSkipTutorialHandler}></StepIntroduction>
                        </div>

                        <div class="form-convert-flow__item">
                            <StepFormConverterAI onCancel={onCancelHandler} onNextStep={onNextStepHandler} onBack={onBackHandler} onSkip={onSkipTutorialHandler}></StepFormConverterAI>
                        </div>

                        <div class="form-convert-flow__item" step-name="howConvert">
                            <StepHowConvert onCancel={onCancelHandler} onNextStep={onNextStepHandler} onBack={onBackHandler}></StepHowConvert>
                        </div>

                        <div class="form-convert-flow__item" step-name="howYouWantConvert" auth-needed="true">
                            <StepHowYouWantConvert onCancel={onCancelHandler} onNextStep={onNextStepHandler} onBack={onBackHandler}></StepHowYouWantConvert>
                        </div>

                        <div class="form-convert-flow__item" step-name="auth">
                            <StepAuth onCancel={onCancelHandler} onNextStep={onNextStepHandler}></StepAuth>
                        </div>

                        <div class="form-convert-flow__item" step-name="chooseTemplate" auth-needed="true">
                            <StepChooseTemplate onCancel={onCancelHandler} onNextStep={onNextStepHandler}></StepChooseTemplate>
                        </div>

                        <div class="form-convert-flow__item" step-name="upload" auth-needed="true">
                            <StepUpload onCancel={onCancelHandler} onNextStep={onNextStepHandler} onUploaded={onUploaded}></StepUpload>
                        </div>


                        <div class="form-convert-flow__item" step-name="formUploaded" auth-needed="true">
                            <StepFormUploaded onCancel={onCancelHandler} onNextStep={onNextStepHandler} fileUrl={state.fileUrl} fileName={state.fileName}></StepFormUploaded>
                        </div>

                        <div class="form-convert-flow__item" step-name="errorRecognizing" auth-needed="true">
                            <StepErrorRecognizing onCancel={onCancelHandler} onNextStep={onNextStepHandler}></StepErrorRecognizing>
                        </div>
                    </div>
                </div>
            )
        },
})

export type FormConvertFlow = InstanceType<typeof FormConvertFlow>