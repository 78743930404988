import { OAuth } from './oauth';
import { gapi } from "gapi-script";

export class GoogleAuth {
    init() {
        if (typeof gapi !== "undefined" && typeof gapi.load === "function") {
            gapi.load('auth2', function () {
                let clientId = document.querySelector('meta[name="google-signin-client_id"]').getAttribute('content');

                gapi.auth2.init({
                    client_id: clientId,
                    scope: 'profile email'
                }).then(function () {
                    gapi.auth2.getAuthInstance().currentUser.listen(OAuth.onSignIn);
                });
            });
        } else {
            console.log('Google auth not supporter on this domain');
        }
    }

    
}


